import React, { useState, useEffect } from 'react';
import { FaHome, FaClipboardList, FaCog, FaPowerOff, FaChartPie, FaComments, FaBriefcase, FaSignInAlt, FaBars, FaCloudUploadAlt  } from 'react-icons/fa';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import './styles/sidebar.css';
import { useUser } from '../context/UserContext'; // Assuming you have a UserContext to manage user state


const Sidebar = () => {
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showPowerMenu, setShowPowerMenu] = useState(false);
    const { user, setUser,  isNewUser,  userPlan, logout } = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const [showTip, setShowTip] = useState(false);
    const [showSecondTip, setShowSecondTip] = useState(false);
    const [showThirdTip, setShowThirdTip] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 768); // Sidebar visible on large screens by default
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Check if it's mobile view

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible); // Toggle sidebar visibility
    };

    // Toggle profile and power menu visibility
    const toggleProfileMenu = () => setShowProfileMenu(!showProfileMenu);
    const togglePowerMenu = () => setShowPowerMenu(!showPowerMenu);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // Handle login redirection
    const handleLoginClick = () => {
        // Redirect to your Google OAuth flow here instead of '/login'
        window.location.href = 'https://app.quickinsights-ai.com/login'; // Change this to your OAuth login URL
    };



    useEffect(() => {
        if (isNewUser && !localStorage.getItem('SettingsTipShown')) {
            setShowTip(true); // Show tip when the user is new
        }
        // Show the second tip if it's a reload after saving settings
        if (localStorage.getItem('settingsSaved') && !localStorage.getItem('SecondTipShown')) {
            setShowSecondTip(true); // Show second tip when settings saved
            localStorage.setItem('SecondTipShown', 'true');
            localStorage.removeItem('settingsSaved'); // Reset the flag
        }
    }, [isNewUser]);
    
    const handleTipClick = () => {
        setShowTip(false);
        localStorage.setItem('SettingsTipShown', 'true');
        navigate('/settings'); // Redirect to settings
        
    };
    
    const handleSecondTipClick = () => {
        setShowSecondTip(false);
        localStorage.setItem('SecondTipShown', 'true');
        setShowThirdTip(true);
    };
    
    const handleThirdTipClick = () => {
        setShowThirdTip(false);
        localStorage.setItem('ThirdTipShown', 'true');
    };
    
    const renderFloatingTip = () => {
        if (!showTip) return null;
        return (
            <div className="side-settings-floating-tip">
                <div className="side-floating-tip-pointer"></div>
                <div className="floating-tip-content">
                    <p>
                        <span className="tip-step-number">1</span> Let's start by saving your settings!
                    </p>
                    <button onClick={handleTipClick} className="tip-cta">Go</button>
                </div>
            </div>
        );
    };
    
    const renderSecondFloatingTip = () => {
        if (!showSecondTip) return null;
        return (
            <div className="requests-floating-tip">
                <div className="side-floating-tip-pointer"></div>
                <div className="floating-tip-content">
                    <p>
                        <span className="tip-step-number">3</span> You can check your made Requests or Insights here!
                    </p>
                    <button onClick={handleSecondTipClick} className="tip-cta">Got it!</button>
                </div>
            </div>
        );
    };
    
    const renderThirdFloatingTip = () => {
        if (!showThirdTip) return null;
        return (
            <div className="home-floating-tip">
                <div className="side-floating-tip-pointer"></div>
                <div className="floating-tip-content">
                    <p>
                        <span className="tip-step-number">4</span> All done! You can now select any text and get insights.
                    </p>
                    <button onClick={handleThirdTipClick} className="tip-cta">Got it!</button>
                </div>
            </div>
        );
    };
    
    

        // Handle logout functionality
        const handleLogout = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
    
                if (!accessToken) {
                    console.warn('No access token found. Skipping API logout request.');
                    performLocalLogout();
                    return;
                }
    
                // Make a POST request to logout the user
                const response = await fetch('https://server.quickinsights-ai.com/api/auth/logout', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
    
                if (response.ok) {
                    const data = await response.json();
                    if (data.success) {
                        console.log('Dashboard logout successful:', data.message);
                        performLocalLogout();
                    } else {
                        console.error('Logout failed:', data.error);
                    }
                } else {
                    console.error(`HTTP error during logout! Status: ${response.status}`);
                    performLocalLogout(); // Proceed with local logout even if API fails
                }
            } catch (error) {
                console.error('Error during logout:', error);
                performLocalLogout(); // Proceed with local logout even on error
            }
        };
    
        // Clear user state and redirect
        const performLocalLogout = () => {
            // Clear user state and local storage
            setIsLoggedIn(false);
            localStorage.removeItem("isLoggedIn");
            localStorage.setItem("isLoggedIn", false);
            setUser(null);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
            localStorage.removeItem('userPlan');

    
            // Notify other tabs or extensions about logout
            const logoutChannel = new BroadcastChannel('logout_channel');
            logoutChannel.postMessage({ action: 'logout', message: 'User logged out' });
            logoutChannel.close();
    
            // Redirect to login page
            window.location.href = 'https://app.quickinsights-ai.com/login';
        };
    
        useEffect(() => {
            const logoutChannel = new BroadcastChannel('logout_channel');
            const socket = new WebSocket('wss://server.quickinsights-ai.com');
    
            let webSocketHandled = false;
            let broadcastHandled = false;
    
            // WebSocket logout handling
            socket.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    if (data.action === 'logout' && !webSocketHandled) {
                        webSocketHandled = true; // Mark WebSocket logout as handled
                        console.log('Logout received via WebSocket:', data.message);
                        performLocalLogout();
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };
    
            // BroadcastChannel logout handling
            logoutChannel.onmessage = (event) => {
                if (event.data.action === 'logout' && !broadcastHandled) {
                    broadcastHandled = true; // Mark BroadcastChannel logout as handled
                    console.log('Logout received via BroadcastChannel:', event.data.message);
                    performLocalLogout();
                }
            };
    
            return () => {
                socket.close();
                logoutChannel.close();
            };
        }, []);



    // Handle screen resize to check if it's mobile or larger view
    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            setIsMobileView(isMobile);
            if (!isMobile) {
                setIsSidebarVisible(true); // Always show sidebar on large screens
            } else {
                setIsSidebarVisible(false); // Hide sidebar by default on mobile
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <>
            {/* Hamburger button only visible on mobile */}
            {isMobileView && (
                <button className={`hamburger ${isSidebarVisible ? 'white' : 'black'}`} onClick={toggleSidebar}>
                    <FaBars />
                </button>
            )}

            {/* Sidebar */}
            <aside className={`sidebar ${isSidebarVisible ? 'visible' : 'hidden'}`}>
                
            <div className="sidebar-header">
             
                {/* Update to link to the homepage component */}
                <NavLink to="/">
                    <img src="quickinsights-logoz.png" alt="QuickInsights Logo" className="logo" />
                </NavLink>
            </div>
            <nav className="sidebar-nav">
                <ul>
                    <li className={location.pathname === '/dashboard' ? 'active' : ''}>
                        <NavLink to="/dashboard" >
                            <FaHome className="icon" /> Home
                        </NavLink>
                        {renderThirdFloatingTip()}
                    </li>
                    <li className={location.pathname === '/requests' ? 'active' : ''}>
                        <NavLink to="/requests" >
                            <FaClipboardList className="icon" /> Requests
                        </NavLink>
                        {renderSecondFloatingTip()}
                    </li>
                    <li className={location.pathname === '/uploads' ? 'active' : ''}>
                        <NavLink to="/uploads" >
                            <FaCloudUploadAlt className="icon" /> Uploads
                        </NavLink>
                    </li>
                    <li className={location.pathname === '/settings' ? 'active' : ''}>
                            <NavLink to="/settings">
                                <FaCog className="icon" /> Settings
                            </NavLink>
                            {renderFloatingTip()}
                        </li>
                    <li className={location.pathname === '/feedback' ? 'active' : ''}>
                        <NavLink to="/feedback" >
                            <FaComments className="icon" /> Feedback
                        </NavLink>
                    </li>

                </ul>
            </nav>
            <footer className="sidebar-footer">
                <div className="footer-icons">
                    {user ? (
                        <div className="profile-icon" onClick={toggleProfileMenu} title="Profile Options">
                            <img src={user.profilePicture} alt={user.name} className="profile-pic" />
                            {showProfileMenu && (
                                <div className="profile-dropdown">
                                    <NavLink to="./account">Account Settings</NavLink>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="login-prompt" onClick={handleLoginClick} title="Login">
                            <FaSignInAlt className="icon" />
                            <span>Log In</span>
                        </div>
                    )}
                    <div className="power-icon" onClick={handleLogout} title="Logout">
                        <FaPowerOff className="icon" />
                    </div>
                </div>
            </footer>
     
        </aside>
         </>
    );
};

export default Sidebar;
