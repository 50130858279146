/* global google */
/* global gapi */
import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, GoogleAuthProvider, signInWithPopup, getRedirectResult, signInWithRedirect, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, updateDoc } from "firebase/firestore"; // Import Firestore


// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDi6fOQqQfalZghWTrW3rJ9ku6Tw9m9GYo",
    authDomain: "quickinsights-284de.firebaseapp.com",
    projectId: "quickinsights-284de",
    storageBucket: "quickinsights-284de.firebasestorage.app",
    messagingSenderId: "1038698941000",
    appId: "1:1038698941000:web:bbecb7b0cd5f193ed1667d",
    measurementId: "G-PZTTG6V6Z7"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore


const CLIENT_ID = "1038698941000-g2qlach0atplvgn6feqe55es06q9eq6j.apps.googleusercontent.com";
const API_KEY = "AIzaSyANKmnIH37WiL8a5CIsQUQU0pB5er9fdkk";
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
const SCOPES = [
    'https://www.googleapis.com/auth/drive.file', // For creating and accessing user-specific files
    'https://www.googleapis.com/auth/drive',     // For broader access, such as reading/writing to any file
  ];



const UserContext = createContext();


// Export the auth instance to use in other parts of your app
export { auth, db, isSignInWithEmailLink, signInWithEmailLink, GoogleAuthProvider, signInWithPopup, getRedirectResult, signInWithRedirect, setPersistence, browserLocalPersistence };

const provider = new GoogleAuthProvider();

export const useUser = () => {
    return useContext(UserContext); // Hook to access user context
};

// Helper function to get the user's plan

export const getUserPlan = (user) => {
    return user ? user.plan : null;  
};



export const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const plan = params.get('plan');
    console.log('Extracted Query Params:', { email, plan }); // Add this for debugging
    return { email, plan };
};



export const UserProvider = ({ children }) => {
    const defaultPlan = 'Free'; // Default plan for testing
    const [user, setUser] = useState(null); // State to store user data
    const [userPlan, setUserPlan] = useState(() => {

        return localStorage.getItem('userPlan');
        
    });
    const [isNewUser, setIsNewUser] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [tokenClient, setTokenClient] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [totalRequests, setTotalRequests] = useState(0);

    // Function to update total requests
    const updateTotalRequests = (newActivityLog) => {
        setTotalRequests(newActivityLog.length);
    };
    useEffect(() => {
        if (userPlan) {
            localStorage.setItem('userPlan', userPlan);
        }
    }, [userPlan]);

    
    useEffect(() => {
        const loadGoogleAPI = () => {
            return new Promise((resolve, reject) => {
                if (window.google && google.accounts) {
                    resolve();
                } else {
                    const interval = setInterval(() => {
                        if (window.google && google.accounts) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 100);
                    setTimeout(() => {
                        clearInterval(interval);
                        reject(new Error("Google API failed to load."));
                    }, 10000); // Timeout after 10 seconds
                }
            });
        };
    
        const initOAuthClient = async () => {
            try {
                await loadGoogleAPI();
                const tokenClient = google.accounts.oauth2.initTokenClient({
                    client_id: CLIENT_ID,
                    scope: SCOPES.join(" "),
                    callback: async (tokenResponse) => {
                        if (tokenResponse.access_token) {
                            localStorage.setItem("access_token", tokenResponse.access_token);
                            setIsSignedIn(true);
                            await fetchUserData(tokenResponse.access_token);
                        } else {
                            handleSignOut();
                        }
                    },
                });
                setTokenClient(tokenClient);
            } catch (error) {
                console.error("Error initializing Google OAuth Client:", error);
                setError(error.message);
            }
        };
    
        const token = localStorage.getItem("access_token");
        if (token) {
            setIsSignedIn(true);
            fetchUserData(token);
        } else {
            initOAuthClient();
        }
    }, []);

    
    useEffect(() => {
        const loadScripts = async () => {
            await loadGis();
            await loadGapi();
        };

        const loadGis = () => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = "https://accounts.google.com/gsi/client";
                script.onload = () => {
                    initGis();
                    resolve();
                };
                document.body.appendChild(script);
            });
        };

        const loadGapi = () => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = "https://apis.google.com/js/api.js";
                script.onload = () => {
                    initGapi();
                    resolve();
                };
                document.body.appendChild(script);
            });
        };

        const initGis = () => {
            const tokenClientInstance = google.accounts.oauth2.initTokenClient({
                client_id: CLIENT_ID,
                scope: SCOPES.join(" "),
                callback: async (tokenResponse) => {
                    if (tokenResponse.access_token) {
                        localStorage.setItem("access_token", tokenResponse.access_token);
                        setIsSignedIn(true);
                        await fetchUserData(tokenResponse.access_token);
                    } else {
                        setIsSignedIn(false);
                    }
                },
            });
            setTokenClient(tokenClientInstance);
            const token = localStorage.getItem("access_token");
            if (token) {
                setIsSignedIn(true);
                fetchUserData(token);
            }
        };

        const initGapi = () => {
            gapi.load("client:auth2", () => {
                gapi.client.init({
                    apiKey: API_KEY,
                    discoveryDocs: DISCOVERY_DOCS,
                }).then(() => {
                  //  console.log("GAPI client initialized successfully");
                  // console.log(gapi.client); // Check the state of gapi.client here
                }).catch((error) => {
                    console.error("Error initializing GAPI client:", error);
                });
            });
        };


        

        loadScripts();
    }, []);


    const wsRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    

useEffect(() => {
    const initializeWebSocket = () => {
        // Close any existing WebSocket before initializing a new one
        if (wsRef.current) {
            wsRef.current.close();
            setIsConnected(false);
        }

        // Only initialize WebSocket if the user is signed in or not
        const ws = new WebSocket('wss://server.quickinsights-ai.com');
        wsRef.current = ws;

        ws.onopen = () => {
            setIsConnected(true);
            // Add a delay before sending the loginPayload
            const delayInMs = 5000; // 3 seconds delay
            setTimeout(() => {
                const loginPayload = {
                    action: 'updateLoginStatus',
                    isLoggedIn: isSignedIn,
                };
                ws.send(JSON.stringify(loginPayload));
             //   console.log('WebSocket connection established:', loginPayload);

                // Send userPlan separately, no condition needed
                const planPayload = {
                    action: "updateUserPlan",
                    userPlan: userPlan,
                };
                ws.send(JSON.stringify(planPayload));
              //  console.log("WebSocket userPlan payload sent:", planPayload);
            }, delayInMs);
        };

        ws.onmessage = (event) => {
            //console.log('WebSocket message received:', event.data);
        };

        ws.onerror = (error) => {
          //  console.error('WebSocket error:', error);
        };

        ws.onclose = () => {
            setIsConnected(false);
        };
    };

    // Initialize or close WebSocket based on isSignedIn
    initializeWebSocket();

    return () => {
        // Cleanup WebSocket on unmount or when isSignedIn changes
        if (wsRef.current) {
            wsRef.current.close();
        }
    };
}, [isSignedIn]);

        
    const handleSignOut = () => {
        
        setUser(null); // Clear user data on sign out
        setIsSignedIn(false);
        localStorage.removeItem('userPlan', userPlan); // Clear stored user plan
        localStorage.removeItem('accessToken'); 
        sessionStorage.removeItem('access_token'); 
        window.location.href = '/login';
    };

    const getFirstName = (user) => {
        return user.given_name || user.name.split(' ')[0]; 
    };
    
    
    // After fetching the user plan, set user state
    const setUserState = async (data) => { 
        setUser(data.user);
        setIsSignedIn(true);

        // Fetch user plan before proceeding with saving user data
        const plan = await fetchUserPlanFromFirestore(data.user.email);

        const firstName = getFirstName(data.user);
        console.log('User\'s Name:', firstName);

        // Pass the email, plan, and firstName to save user data
        await saveUserPlanToFirestore(data.user.email, plan, firstName); 
    };




    // Function to fetch user data using access token
    const fetchUserData = async (accessToken) => {
        try {
            const response = await fetch('https://server.quickinsights-ai.com/api/auth/user', {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            const data = await response.json();
            if (data.success) {
                setUserState(data); 

            } else {
                handleSignOut();  // Centralize sign-out handling
            }
        } catch (error) {
            handleSignOut(); // Centralize sign-out handling
        }
    };


const processMagicLink = () => {
    const emailLink = window.location.href;
    const params = new URLSearchParams(window.location.search);
    const savedEmail = params.get('email');
    const expiryTimestamp = params.get('expiry');
    const authUrl = params.get('authUrl');

    // Check if the URL contains the magic link parameters (email, expiry, authUrl)
    if (!savedEmail || !expiryTimestamp || !authUrl) {
        //console.log('No magic link found in the URL, skipping magic link processing.');
        return; // Exit early, don't process magic link
    }

    const currentTime = Date.now();
    console.log('Current time:', currentTime);
    console.log('Saved email:', savedEmail);
    console.log('Expiry timestamp:', expiryTimestamp);
    console.log('Auth URL:', authUrl);

    // Check if the magic link has already been processed
    const isProcessed = sessionStorage.getItem('magicLinkProcessed');
    if (isProcessed === 'true') {
        console.log('Magic link already processed, avoiding duplicate execution.');
        setIsLoading(false); // Update loading state
        return;
    }

    // Check for expired or invalid link
    if (currentTime > Number(expiryTimestamp)) {
        console.error('The magic link has expired.');
        sessionStorage.setItem('magicLinkProcessed', 'true'); // Mark as processed to prevent repeat alerts
        alert('The magic link has expired. You will be redirected to the login page.');
        setTimeout(() => {
            console.log('Redirecting to /login...');
            window.location.href = '/login'; // Redirect to login page
        }, 1000);
        setIsLoading(false); // Update loading state
        return;
    }

    // Process valid magic link
    if (isSignInWithEmailLink(auth, emailLink)) {
        console.log('Valid magic link received:', emailLink);
        localStorage.setItem('emailForSignIn', savedEmail);
        sessionStorage.setItem('magicLinkProcessed', 'true');

        if (authUrl) {
            console.log('Redirecting to OAuth consent screen...');
            setTimeout(() => {
                console.log('Redirecting to auth URL:', authUrl);
                window.location.href = authUrl; // Redirect to OAuth URL
            }, 500);
            return; // Early return to prevent further execution
        }
    } else if (authUrl) {
        console.log('Redirecting to OAuth consent screen...');
        sessionStorage.setItem('magicLinkProcessed', 'true');

        setTimeout(() => {
            console.log('Redirecting to auth URL:', authUrl);
            window.location.href = authUrl; // Redirect to OAuth URL
        }, 500);
        return; // Early return to prevent further execution
    } else {
        console.error('No valid sign-in link or auth URL found.');
        setError('No valid link found.');
    }

    setIsLoading(false); // Update loading state if no redirects happen
};

// Call the processing function on mount
useEffect(() => {
    processMagicLink(); 
}, []); // Empty dependency array to ensure it runs once




// Fetch user data when component mounts
useEffect(() => {
    setIsLoading(true);
    signIn().finally(() => setIsLoading(false));
}, []);






// Unified sign-in function to handle OAuth
const signIn = async () => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            await fetchUserData(accessToken); // Fetch user data if accessToken exists
            setIsSignedIn(true); // User is signed in if data is retrieved successfully
            
            return true; // Return true to indicate success
        } else {
            // console.log('No access token found. User needs to sign in.');
            setIsSignedIn(false); // User needs to sign in
            setUserPlan(defaultPlan);
            return false; // Return false to indicate failure
        }
    } catch (error) {
        console.error('Sign-in error:', error);
        setIsSignedIn(false); // Update state on error
        return false; // Return false to indicate failure
    }
};



// Function to fetch user plan from Firestore
const fetchUserPlanFromFirestore = async (email) => {
    try {
        const userDocRef = doc(db, "users", email);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            // Set the user plan from Firestore 
            const plan = userData.plan;
            setUserPlan(plan); // Update the state with the fetched or default plan
            return plan; // Return the fetched plan or 'Free' if not present
        } else {
            // If user document doesn't exist, assume new user and create document with default plan
            console.log("New user detected, setting default plan to Free.");
            await saveUserPlanToFirestore(email, 'Free'); // Create a new user document with 'Free' plan
            setUserPlan('Free'); // Set the state to 'Free'
            return 'Free';  // Return 'Free' as the plan for new users
        }
    } catch (err) {
        console.error("Error fetching user plan:", err);
        setUserPlan('Free'); // Ensure the state is updated to 'Free' in case of error
        return 'Free'; // Default to 'Free' in case of error
    }
};


 // Save user plan to Firestore function
const saveUserPlanToFirestore = async (
    email,
    plan, // The plan value to save, default is 'Free' if no plan is provided
    firstName, // Ensure firstName is the actual first name
    tokensUsed = 0,
    insightsTokensUsed = 0,
    db = getFirestore(),
    invitedCount = 0
) => {
    if (!email) {
        console.error("Email is required to save the plan.");
        return;
    }

    // Log the incoming parameters for debugging
    console.log('Incoming Params:', { email, plan, firstName, tokensUsed, insightsTokensUsed, invitedCount });

    // Function to generate a referral code if not already available
    const generateReferralCode = () =>
        `QI-${Math.random().toString(36).substr(2, 8).toUpperCase()}`;

    try {
        const userDocRef = doc(db, "users", email);
        const userDocSnapshot = await getDoc(userDocRef);

        if (!userDocSnapshot.exists()) {
            setIsNewUser(true);
            // If user document doesn't exist, create it with default values
            const newUserData = {
                plan, // The plan assigned to the user
                tokensUsed: 0, // Default tokens used
                insightsTokensUsed: 0, // Default insights tokens used
                invitedCount: 0, // Default invited count
                referralCode: generateReferralCode(), // Generate a new referral code
                firstName, // Include first name in the saved user data
                lastResetDate: new Date().toISOString(), 
            };

            await setDoc(userDocRef, newUserData); // Save new user data to Firestore
            console.log("New user document created:", newUserData);

            if (firstName) {
                // Ensure firstName is read before sending the email
                console.log("Sending welcome email for:", firstName);
                await sendEmailToList(email, firstName);
            } else {
                console.error("First name is missing. Skipping welcome email.");
            }
        } else {
            setIsNewUser(false);
            // If the document exists, update it with the incoming data
            console.log("User document found, updating user data.");
            const existingData = userDocSnapshot.data();

            const updatedTokensUsed = parseInt(existingData.tokensUsed, 10) + (parseInt(tokensUsed, 10) || 0);
            const updatedInsightsTokensUsed = parseInt(existingData.insightsTokensUsed, 10) + (parseInt(insightsTokensUsed, 10) || 0);
            const updatedInvitedCount = parseInt(existingData.invitedCount, 10) + (parseInt(invitedCount, 10) || 0);
            const referralCode = existingData.referralCode || generateReferralCode();

            const updatedUserData = {
                ...existingData, // Preserve existing data
                plan, // Update plan to the new value or keep existing
                referralCode, // Ensure referral code is preserved or generated
                tokensUsed: updatedTokensUsed,
                insightsTokensUsed: updatedInsightsTokensUsed,
                invitedCount: updatedInvitedCount,
                firstName: firstName || existingData.firstName, 
                lastResetDate: existingData.lastResetDate || new Date().toISOString(),
            };

            await setDoc(userDocRef, updatedUserData, { merge: true });
            console.log("User plan successfully updated:", updatedUserData);
        }
    } catch (error) {
        console.error("Error saving user data to Firestore:", error);
    }
};


 // Function to send welcome email
let emailSent = false; // Flag to ensure email is sent only once

const sendEmailToList = async (email, firstName) => {
    if (emailSent) {
        console.log("Welcome email already sent.");
        return;
    }

    emailSent = true; // Lock to prevent multiple calls

    if (!firstName) {
        console.error("First name is required to send the welcome email.");
        emailSent = false;
        return;
    }

    try {
        console.log("Preparing to send welcome email to:", email, "Name:", firstName);

        const response = await fetch("https://server.quickinsights-ai.com/api/auth/add/new-user", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, firstName }),
        });

        const data = await response.json();

        if (data.success) {
            console.log("Welcome email sent successfully!");
        } else {
            console.error("Error sending welcome email:", data.error);
        }
    } catch (error) {
        console.error("Failed to send welcome email:", error);
    } finally {
        emailSent = false; // Reset lock if needed for other users
    }
};








useEffect(() => {
    if (user && user.email) {
        const params = getQueryParams();
        if (params.plan) {
            setUserPlan(params.plan);
            saveUserPlanToFirestore(user.email, params.plan, db);
        } else {
            fetchUserPlanFromFirestore(user.email); // Fetch based on user email
        }
    }
}, [user, db]);


useEffect(() => {
    changePlanByEmail(); 
}, []); 

const changePlanByEmail = async (email, plan) => {
    // Validate input parameters
    if (!email || !plan) {
        //console.error('Invalid parameters passed to changePlanByEmail:', { email, plan });
        return;
    }

    console.log('Changing plan for user:', { email, plan }); // Debug log

    try {
        // Save the upgraded plan to Firestore
        const firstName = user?.given_name || user?.name?.split(' ')[0]; // Extract first name if available
        await saveUserPlanToFirestore(email, plan, firstName);

        console.log(`User plan for ${email} updated to ${plan} in Firestore.`);
        setUserPlan(plan); // Update the plan in local state (if required)
    } catch (error) {
        console.error('Error updating user plan in Firestore:', error);
    }
};


        // Optional: You can call saveUserPlanToDrive when you want to save the plan
        const handleSavePlan = () => {
            saveUserPlanToFirestore(userPlan); // Pass the current user plan to save
        };




    // Function to handle user logout
    const logout = () => {
        if (isSignedIn && window.gapi) {
            gapi.auth2.getAuthInstance().signOut(); // Sign out from Google
        }
        setUser(null); // Clear user data
        localStorage.removeItem('user'); // Clear user from local storage
        localStorage.removeItem('accessToken'); // Clear access token
        localStorage.removeItem('userPlan', userPlan);      
    };
    




    return (
        <UserContext.Provider value={{ user, isLoading, error, setUser, signIn, saveUserPlanToFirestore, isNewUser, setIsNewUser, isSignedIn, userPlan, updateTotalRequests, changePlanByEmail, getUserPlan, getQueryParams, logout }}>
            {children}
        </UserContext.Provider>
    );
  
};

export default UserContext;




