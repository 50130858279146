import React, { useState, useEffect } from 'react';
import './ProSettings.css'; // Import the CSS file for pro settings

const ProSettings = ({ 
  selectedAIContext, setSelectedAIContext, 
  fullPageInsights, setFullPageInsightsToggle, 
  qiSearchBar, setQiSearchBarToggle, 
  gmailReplies, setGmailRepliesToggle,
  youtubeInsights, setYoutubeInsightsToggle 
}) => {
    // Array of AI context options
    const aiContexts = [
        'Customer Support',
        'Marketing',
        'Educative',
        'Creative',
        'Technicality',
        'News',
        'None'
    ];

    // Load AI context and advanced text analysis settings from local storage when the component mounts
    useEffect(() => {
        const savedAIContext = localStorage.getItem('selectedAIContext');
        if (savedAIContext) {
            setSelectedAIContext(savedAIContext);
        }
    }, [setSelectedAIContext]);

    // Function to save the selected AI context
    const handleAIContextChange = (context) => {
        setSelectedAIContext(context);
        localStorage.setItem('selectedAIContext', context); // Save AI context to local storage
    };

    return (
        <div className="pro-settings-container">
            {/* Group for AI Context Settings */}
            <div className="group-container">
                <div className="group-title">
                    <h4>QI Context Settings</h4>
                    <p>Select the context in which the QI should generate content.</p>
                </div>
                
                <div className="ai-context-options">
                    {aiContexts.map((context, index) => (
                        <label key={index} className={`context-label ${selectedAIContext === context ? 'active' : ''}`}>
                            <input
                                type="radio"
                                name="aiContext"
                                value={context}
                                checked={selectedAIContext === context}
                                onChange={() => handleAIContextChange(context)}
                                className="context-radio"
                            />
                            {context}
                        </label>
                    ))}
                </div>
            </div>

            {/* Group for Full Page Insights */}
            <div className="group-container">
                <div className="group-title">
                    <h4>Full Page Insights</h4>
                    <p>Get insights for an entire page content from the main extension popup.</p>
                </div>
                
                <label className="toggle-label" htmlFor="fullPageInsightsToggle">
                    <span className={`toggle ${fullPageInsights ? 'active' : ''}`}>
                        <span className="toggle-circle"></span>
                    </span>
                    <span className={`toggle-text ${fullPageInsights ? 'on' : 'off'}`}>
                        {fullPageInsights ? 'On' : 'Off'}
                    </span>
                    <input
                        type="checkbox"
                        id="fullPageInsightsToggle"
                        checked={fullPageInsights}
                        onChange={(e) => setFullPageInsightsToggle(e.target.checked)}
                        className="toggle-input"
                    />
                </label>
            </div>

            {/* Group for QI Search Bar */}
            <div className="group-container">
                <div className="group-title">
                    <h4>QI Search Bar</h4>
                    <p>Enable or disable the QI Search Bar feature.</p>
                </div>
                
                <label className="toggle-label" htmlFor="qiSearchBarToggle">
                    <span className={`toggle ${qiSearchBar ? 'active' : ''}`}>
                        <span className="toggle-circle"></span>
                    </span>
                    <span className={`toggle-text ${qiSearchBar ? 'on' : 'off'}`}>
                        {qiSearchBar ? 'On' : 'Off'}
                    </span>
                    <input
                        type="checkbox"
                        id="qiSearchBarToggle"
                        checked={qiSearchBar}
                        onChange={(e) => setQiSearchBarToggle(e.target.checked)}
                        className="toggle-input"
                    />
                </label>
            </div>

            {/* Group for Gmail Replies */}
            <div className="group-container">
                <div className="group-title">
                    <h4>Gmail Replies</h4>
                    <p>Enable or disable the Gmail reply feature for Pro plan users.</p>
                </div>
                
                <label className="toggle-label" htmlFor="gmailRepliesToggle">
                    <span className={`toggle ${gmailReplies ? 'active' : ''}`}>
                        <span className="toggle-circle"></span>
                    </span>
                    <span className={`toggle-text ${gmailReplies ? 'on' : 'off'}`}>
                        {gmailReplies ? 'On' : 'Off'}
                    </span>
                    <input
                        type="checkbox"
                        id="gmailRepliesToggle"
                        checked={gmailReplies}
                        onChange={(e) => setGmailRepliesToggle(e.target.checked)}
                        className="toggle-input"
                    />
                </label>
            </div>
            {/* Group for YouTube Insights */}
            <div className="group-container">
                <div className="group-title">
                    <h4>YouTube Insights</h4>
                    <p>Enable or disable video transcription, summary and AskQI chat features on YouTube.</p>
                </div>
                
                <label className="toggle-label" htmlFor="youtubeInsightsToggle">
                    <span className={`toggle ${youtubeInsights ? 'active' : ''}`}>
                        <span className="toggle-circle"></span>
                    </span>
                    <span className={`toggle-text ${youtubeInsights ? 'on' : 'off'}`}>
                        {youtubeInsights ? 'On' : 'Off'}
                    </span>
                    <input
                        type="checkbox"
                        id="youtubeInsightsToggle"
                        checked={youtubeInsights}
                        onChange={(e) => setYoutubeInsightsToggle(e.target.checked)}
                        className="toggle-input"
                    />
                </label>
            </div>

        </div>
    );
};

export default ProSettings;
