import React, { useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Container, ContentWithPaddingXl } from "../Homepage/elements/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "../Homepage/elements/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../Homepage/elements/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../Homepage/images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../Homepage/images/svg-decorator-blob-7.svg";
import { useUser, getQueryParams } from "../../context/UserContext.js";

const Subheading = tw(SubheadingBase)`mb-2 text-[#07c692] text-lg`;
const Heading = tw(SectionHeading)`text-[#07c692] text-2xl`;
const SuccessContainer = tw(ContentWithPaddingXl)`flex flex-col items-center`;
const Message = tw.p`text-sm text-gray-700 mt-4`;
const Button = styled(PrimaryButtonBase)`
  ${tw`rounded-full mt-6 tracking-wider py-3 px-8 bg-[#07c692] transform hocus:translate-x-px hocus:-translate-y-px`}
  border: 2px solid #07c692;
  &:hover {
    border-color: #0a9a7c;
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-32 w-32 fill-[#07c692] opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-32 w-32 fill-[#07c692] opacity-25 transform translate-x-2/3 translate-y-1/2`}
`;

const Success = () => {
  const { changePlanByEmail } = useUser();
  const { email, plan } = getQueryParams();

  useEffect(() => {
    if (email && plan) {
      changePlanByEmail(email, plan)
        .catch((error) => console.error('Error updating plan:', error));
    } else {
      console.error('Missing email or plan in query parameters.');
    }
  }, [email, plan, changePlanByEmail]);

  
  const handleDashboardRedirect = () => {
    window.location.href = '/dashboard'; // Redirect directly to the dashboard
  };
  
  return (
    <Container>
      <SuccessContainer>
        <Subheading>Payment Success</Subheading>
        <Heading>Congratulations!</Heading>
        <Message>
          Your plan has been successfully updated to: <strong style={{ color: '#07c692' }}>{plan}</strong>.
        </Message>
        <Message>Thank you for upgrading and supporting us!</Message>
        <Button onClick={handleDashboardRedirect}>Go to Dashboard</Button> 
        </SuccessContainer>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

export default Success;
