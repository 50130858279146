// src/components/UsageStatistics.js
import React, { useEffect, useState } from 'react';
import { useUser } from '../context/UserContext'; // Adjust the import path as needed
import CountUp from 'react-countup'; // Import CountUp
import './styles/usageStatistics.css';

const UsageStatistics = () => {
    const { user, isSignedIn, signIn, userPlan } = useUser(); // Get user and userPlan from context
    const [totalRequests, setTotalRequests] = useState(0);
    
    const isHighestPlan = userPlan === 'Enterprise'; // Check against userPlan
    const [loading, setLoading] = useState(false);

    const [insightsRetrieved, setInsightsRetrieved] = useState({
        selectedText: 0,
        pageInsights: 0,
        searchInsights: 0,
        youtubeInsights: 0,
    });

    


    useEffect(() => {
        if (isSignedIn && user) {
            loadActivityLogFromGoogleDrive();
        } else {
            setLoading(false); // Stop loading if user is not signed in
        }
    }, [user, isSignedIn, signIn]);

    const loadActivityLogFromGoogleDrive = async () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.warn('No access token found. Please sign in.');
            return;
        }
    
        try {
            const fileName = `${user.id}_requests.json`;
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="${fileName}"&mimeType="application/json"`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            const data = await response.json();
    
            if (data.files && Array.isArray(data.files) && data.files.length > 0) {
                const fileId = data.files[0]?.id;
    
                if (!fileId) {
                    console.warn('File ID not found in the response.');
                    setTotalRequests(0);
                    setInsightsRetrieved({ selectedText: 0, pageInsights: 0, searchInsights: 0, youtubeInsights: 0 });
                    return;
                }
    
                const fileResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
    
                if (!fileResponse.ok) {
                    throw new Error('Failed to fetch activity log data');
                }
    
                const logData = await fileResponse.json();
    
                if (logData && logData.requests) {
    
                    // Initialize counters for each category
                    const categoryCounts = {
                        selectedText: { requests: 0, responses: 0 },
                        pageInsights: { requests: 0, responses: 0 },
                        searchInsights: { requests: 0, responses: 0 },
                        youtubeInsights: { requests: 0, responses: 0 },
                    };
    
                    // Flatten or transform the grouped requests data for easier use in your React app
                    const flattenedRequests = Object.entries(logData.requests || {}).flatMap(([date, entries]) =>
                        entries.map(entry => ({
                            date,
                            categoryInitials: entry.category === 'selectedText' ? 'ST' : entry.category === 'pageInsights' ? 'PI' : entry.category === 'searchInsights' ? 'SI' : entry.category === 'youtubeInsights' ? 'YTI' : '',
                            ...entry,
                        }))
                    );
    
                    // Count requests and responses per category
                    flattenedRequests.forEach(request => {
                        const responseArray = Array.isArray(request.response) ? request.response : [request.response];
                        const nonEmptyResponses = responseArray.filter(res => res && res.trim() !== '');
    
                        if (request.categoryInitials === 'ST') {
                            categoryCounts.selectedText.requests++;
                            categoryCounts.selectedText.responses += nonEmptyResponses.length; // Count non-empty responses
                        } else if (request.categoryInitials === 'PI') {
                            categoryCounts.pageInsights.requests++;
                            categoryCounts.pageInsights.responses += nonEmptyResponses.length; // Count non-empty responses
                        } else if (request.categoryInitials === 'SI') {
                            categoryCounts.searchInsights.requests++;
                            categoryCounts.searchInsights.responses += nonEmptyResponses.length; // Count non-empty responses
                        } else if (request.categoryInitials === 'YTI') {
                            categoryCounts.youtubeInsights.requests++;
                            categoryCounts.youtubeInsights.responses += nonEmptyResponses.length; // Count non-empty responses
                        }
                    });
    
                    // Set state with total requests and insights counts
                    setTotalRequests(flattenedRequests.length);
                    setInsightsRetrieved({
                        selectedText: categoryCounts.selectedText.responses,
                        pageInsights: categoryCounts.pageInsights.responses,
                        searchInsights: categoryCounts.searchInsights.responses,
                        youtubeInsights: categoryCounts.youtubeInsights.responses,
                    });
                } else {
                    //console.warn('No requests found in the activity log data.');
                    setTotalRequests(0);
                    setInsightsRetrieved({ selectedText: 0, pageInsights: 0, searchInsights: 0, youtubeInsights: 0 });
                }
            } else {
             //   console.warn('No activity log found, creating a new one');
                setTotalRequests(0);
                setInsightsRetrieved({ selectedText: 0, pageInsights: 0, searchInsights: 0, youtubeInsights: 0 });
            }
        } catch (error) {
            console.error('Error loading activity log from Google Drive:', error);
        }
    };
    
    


    return (
        <div className="usage-statistics">
            <div className="stat-card">
                <h3>Total Requests</h3>
                <div className="stat-number">
                    <CountUp start={0} end={totalRequests} duration={2.0} />
                </div>
            </div>
            <div className="stats-card-insights">
                <div className="stat-card-text">
                    <h3>Text Insights</h3>
                    <div className="stat-number">
                        <CountUp 
                            start={0} 
                            end={insightsRetrieved.selectedText || 0} 
                            duration={2.0} 
                        />
                    </div>
                </div>
                {/* Show Page Insights and Search Insights only for Pro or Enterprise plans */}
                {(userPlan === 'Pro' || userPlan === 'Enterprise') && (
                        <>
                            <div className="stat-card-page">
                                <h3>Page Insights</h3>
                                <div className="stat-number">
                                    <CountUp 
                                        start={0} 
                                        end={insightsRetrieved.pageInsights || 0} 
                                        duration={2.0} 
                                    />
                                </div>
                            </div>
                            <div className="stat-card-search">
                                <h3>Search Insights</h3>
                                <div className="stat-number">
                                    <CountUp 
                                        start={0} 
                                        end={insightsRetrieved.searchInsights || 0} 
                                        duration={2.0} 
                                    />
                                </div>
                            </div>
                            <div className="stat-card-search">
                                <h3>Youtube Insights</h3>
                                <div className="stat-number">
                                    <CountUp 
                                        start={0} 
                                        end={insightsRetrieved.youtubeInsights || 0} 
                                        duration={2.0} 
                                    />
                                </div>
                            </div>
                        </>
                )}
            </div>



            <div className="stat-card">
                <h3>Current Plan</h3>
                <div className="plan-name">
                    {userPlan} 
                </div>
                {!isHighestPlan && (
                    <a href="/billing" className="upgrade-button" style={{ textDecoration: 'none' }}>View Plans</a>
                )}
            </div>
        </div>
    );
};

export default UsageStatistics;
