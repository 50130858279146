import React, { useState } from 'react';
import { useUser } from '../../context/UserContext';
import { getFirestore, doc, deleteDoc } from 'firebase/firestore';
import './account.css';

const AccountSettings = () => {
    const { user, isSignedIn } = useUser();
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [feedbackReason, setFeedbackReason] = useState('');
    const [additionalFeedback, setAdditionalFeedback] = useState('');
    const [showDataSection, setShowDataSection] = useState(true); // Open by default
    const [showDeleteSection, setShowDeleteSection] = useState(true); // Open by default
    const [showBrowserDataSection, setShowBrowserDataSection] = useState(true); // Inner accordion, open by default
    const [showGoogleDriveDataSection, setShowGoogleDriveDataSection] = useState(true); // Inner accordion, open by default
    const [showBillingDataSection, setShowBillingDataSection] = useState(true); // Inner accordion, open by default
    const [showFirestoreDataSection, setShowFirestoreDataSection] = useState(true); // Inner accordion, open by default

    const toggleBrowserDataSection = () => setShowBrowserDataSection(prev => !prev);
    const toggleGoogleDriveDataSection = () => setShowGoogleDriveDataSection(prev => !prev);
    const toggleBillingDataSection = () => setShowBillingDataSection(prev => !prev);
    const toggleFirestoreDataSection = () => setShowFirestoreDataSection(prev => !prev);



    const reasons = [
        "I didn't know how to use QuickInsights",
        "QuickInsights kept joining my failing",
        "The Insights results were not good enough",
        "Summaries are not good enough",
        "Language not supported",
        "Too expensive",
        "I don't need it anymore",
        "Switched to another tool",
        "Lack of features in current plan",
        "Other",
    ];

    // Function to delete user data from Firestore
    const deleteUserFromFirestore = async (email) => {
        const db = getFirestore();
        const userDocRef = doc(db, "users", email);
        
        try {
            await deleteDoc(userDocRef); // Delete the user document from Firestore
            console.log("User document successfully deleted from Firestore.");
        } catch (error) {
            console.error("Error deleting user document from Firestore:", error);
        }
    };
    

    const deleteGoogleDriveFiles = async (accessToken) => {
        try {
            const query = "name contains '_requests.json' or name contains 'settings_'";
            const listResponse = await fetch(`https://www.googleapis.com/drive/v3/files?q=${encodeURIComponent(query)}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const fileList = await listResponse.json();

            if (fileList.files && fileList.files.length > 0) {
                for (const file of fileList.files) {
                    await fetch(`https://www.googleapis.com/drive/v3/files/${file.id}`, {
                        method: 'DELETE',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                }
            }
        } catch (error) {
            console.error('Error deleting files from Google Drive:', error);
        }
    };

    const handleDeleteAccount = async () => {
        if (!feedbackReason) {
            alert('Please select a reason for deleting your account.');
            return;
        }
    
        localStorage.setItem("feedback&moreinfo", JSON.stringify({ feedbackReason, additionalFeedback }));
    
        setIsProcessing(true);
    
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log('Access Token:', accessToken); // Log the access token for debugging
    
            // Check if the token is missing or expired
            if (!accessToken) {
                throw new Error('Access token is missing.');
            }
            localStorage.removeItem('SettingsTipShown')
            localStorage.removeItem('SecondTipShown')
            localStorage.removeItem('ThirdTipShown')
            localStorage.removeItem('SaveTipShown')

            // Get user details (name and email) from the context
            const { name, email } = user || {};  // Use user from context

            if (!name || !email) {
                throw new Error('User details are missing.');
            }

    
            // 2. Send feedback and user details to backend
            const response = await fetch('https://server.quickinsights-ai.com/api/auth/sendFeedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    feedbackReason,
                    additionalFeedback,
                    name,
                    email
                }),
            });
    
            console.log('Response from sendFeedback API:', response); // Log the response for debugging
    
            if (!response.ok) {
                const error = await response.json();
                console.error('Failed to notify admin of account deletion:', error);
                throw new Error('Failed to notify admin of account deletion.');
            }
    
            // 3. Delete files from Google Drive if necessary
            await deleteGoogleDriveFiles(accessToken);
    
            // 4. Revoke the Google OAuth token
            const revokeResponse = await fetch('https://accounts.google.com/o/oauth2/revoke', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: `token=${accessToken}`,
            });
    
            if (!revokeResponse.ok) {
                console.error('Failed to revoke Google token');
                throw new Error('Failed to revoke Google token.');
            }
    
            // 5. Remove the access token from local storage
            localStorage.removeItem('accessToken');
        
            // 6. Delete user from Firestore
            await deleteUserFromFirestore(user.email);
    
            // 7. Notify user and redirect
            alert('Your account and associated data have been deleted successfully.');
            window.location.href = '/login'; // Redirect user to homepage or login page
        } catch (error) {
            console.error('Error deleting account:', error);
            alert('An error occurred while deleting your account. Please try again.');
        } finally {
            setIsProcessing(false); // Turn off loading indicator
            setShowPopup(false);    // Close any open popups (optional)
        }
    };

    
    

    const openPopup = () => setShowPopup(true);
    const closePopup = () => setShowPopup(false);

    const toggleDataSection = () => setShowDataSection(prev => !prev);
    const toggleDeleteSection = () => setShowDeleteSection(prev => !prev);

    if (!isSignedIn) {
        return <p>No user information available. Please sign in.</p>;
    }

    return (
    <div className="account-settings-container">
        <h2 className="account-settings-title">Account Settings</h2>
        {user ? (
            <div className="profile-card">
                <img src={user.profilePicture} alt={user.name} className="profile-image" />
                <div className="profile-info">
                    <h3>{user.name}</h3>
                    <p>Email: {user.email}</p>
                </div>
            </div>
        ) : (
            <p>Please sign in to view your account information.</p>
        )}

    
        {/* Get your data section with smaller width */}
        <div className={`accordion-section get-data-section`}>
            <button className="accordion" onClick={toggleDataSection}>
                Get Your Data
            </button>
            {showDataSection && (
                <div className="accordion-content">
                    <div className="inner-accordion">

                      {/* New section for Firestore data */}
                    <div className="inner-accordion-title" onClick={toggleFirestoreDataSection}>
                        Data stored by QuickInsights
                    </div>
                    {showFirestoreDataSection && (
                        <div className="inner-accordion-content">
                            <p>QuickInsights stores your plan information, user preferences, and other related data in Firestore. This data is used to enhance your experience and ensure consistent service across devices.</p>
                            <p>If you'd like, you can request a copy of your stored data or delete it along with your account.</p>
                        </div>
                    )}
                    <div className="inner-accordion-title" onClick={toggleBrowserDataSection}>
                        Data stored in your browser
                    </div>
                    {showBrowserDataSection && (
                        <div className="inner-accordion-content">
                            <p>QuickInsights stores temporary data in your browser, such as app settings and other configurations. This data is kept locally and is not accessible unless explicitly saved by you.</p>

                            <div className="note-section">
                            <div className="note-section">
                                <div className="note note-one">
                                    <p><strong>Note:</strong> You will not see any saved insights if you open QuickInsights in a different browser, as this data is stored locally and is not shared across browsers.</p>
                                </div>

                                <div className="note note-two">
                                    <p><strong>Note:</strong> All locally stored data, including unsaved insights, will be permanently lost if you uninstall the extension. We cannot assist in recovering this data.</p>
                                </div>
                            </div>

                            </div>
                        </div>
                    )}
                          <div className="inner-accordion-title" onClick={toggleGoogleDriveDataSection}>
                        Data stored in your Google Drive</div>
                        {showGoogleDriveDataSection && (
                        <div className="inner-accordion-content">
                            <p>If configured, your settings, plan information, requests, and uploads are stored in your Google Drive. You have the ability to directly download, view, or delete this data from your Google Drive.</p>
                            
                            {user ? (
                                <button 
                                    className="view-drive-btn" 
                                    onClick={() => {
                                        // Open Google Drive for the signed-in user
                                        // If the user is signed in and we are sure about the index (u/0, u/1, etc.)
                                        const userIndex = 0;  // Assuming user is the first signed-in user. You can adjust this logic if needed.
                                        window.open(`https://drive.google.com/drive/u/${userIndex}/home`, '_blank');
                                    }}
                                >
                                    Open Google Drive
                                </button>
                            ) : (
                                <p>Please sign in to view your Google Drive.</p>
                            )}
                        </div>
                        )}

                        <div className="inner-accordion-title" onClick={toggleBillingDataSection}>Billing Data</div>
                        {showBillingDataSection && (
                        <div className="inner-accordion-content">
                            <p>A limited amount of data is stored for billing and accounting purposes. All payment-related information is securely handled by Stripe and PayPal, ensuring your payment details are not stored on QuickInsights.</p>
                        </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    
        {/* Deleting your account section with larger width */}
        <div className={`accordion-section delete-account-section`}>
            <button className="accordion" onClick={toggleDeleteSection}>
                Deleting Your Account
            </button>
            {showDeleteSection && (
                <div className="accordion-content">
                    <p>This is an irreversible operation. We will not be able to assist with recovery after account deletion. All your data will be permanently deleted.</p>
                    <button
                        className="delete-account-btn"
                        onClick={openPopup}
                        disabled={isProcessing}
                    >
                        {isProcessing ? 'Processing...' : 'Delete Account'}
                    </button>
                </div>
            )}
        </div>
    
        {/* Popup for confirmation */}
        {showPopup && (
            <div className="delete-popup-overlay">
                <div className="delete-popup">
                    <div className="popup-content">
                        <h3>Before you go, please help us improve</h3>
                        <p>We appreciate your feedback and it helps us make QuickInsights more accessible and easy to use.</p>
                        <label htmlFor="reason-dropdown">Reason for leaving:</label>
                        <select
                            id="reason-dropdown"
                            value={feedbackReason}
                            onChange={(e) => setFeedbackReason(e.target.value)}
                        >
                            <option value="">Select a reason</option>
                            {reasons.map((reason, index) => (
                                <option key={index} value={reason}>
                                    {reason}
                                </option>
                            ))}
                        </select>
                        <textarea
                            placeholder="Any additional feedback? (optional)"
                            value={additionalFeedback}
                            onChange={(e) => setAdditionalFeedback(e.target.value)}
                        />
                        <div className="popup-actions">
                            <button className="cancel-btn" onClick={closePopup}>
                                Cancel
                            </button>
                            <button className="confirm-btn" onClick={handleDeleteAccount}>
                                Confirm and Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
    
    );
};

export default AccountSettings;
