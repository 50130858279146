import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { UserProvider } from './context/UserContext'; // Import UserProvider
import './App.css'; // Your main app styles
import './pages/Homepage/homepage.css';
import Sidebar from './components/sidebar'; // Sidebar component
import Header from './components/header'; // Import Header
import Dashboard from './components/dashboard'; // Dashboard component

import Account from './pages/Account/account'; 
import Requests from './pages/Requests/requests';
import Settings from './pages/Settings/settings';
import Uploads from './pages/Uploads/Uploads';
import Referral from './pages/Referral/Referral';
import Success from './pages/Success/Success';
import Cancel from './pages/Cancel/Cancel';

import Homepage from './pages/Homepage/homepage'; // Import Homepage
import Contact from './pages/Homepage/pages/ContactUs'; // Import Contact page
import Login from './pages/Homepage/pages/Login';
import Billing from './pages/Billing/Billing';
import Feedback from './pages/Feedback/Feedback';


import { Elements } from '@stripe/react-stripe-js'; // Import Elements provider
import { loadStripe } from '@stripe/stripe-js';
import AccountSettings from './pages/Account/account';
import PrivacyPolicy from './pages/Homepage/pages/PrivacyPolicy';
import TermsOfUse from './pages/Homepage/pages/TermsOfUse';



const App = () => {
    const navigate = useNavigate();  
    const location = useLocation(); 
    const [initialRoute, setInitialRoute] = useState('/');

    useEffect(() => {
        // Extract access token from URL hash on component mount
        const hash = window.location.hash;
        if (hash) {
            const params = new URLSearchParams(hash.replace('#', '?'));
            const accessToken = params.get('access_token');
            if (accessToken) {
                localStorage.setItem('accessToken', accessToken); // Save the access token
                window.location.hash = ''; // Clear the hash for cleaner URL
            }
        }
    }, []);
    
    useEffect(() => {
        const hostname = window.location.hostname;

        if (hostname === 'app.quickinsights-ai.com') {
            // Check if the path starts with a "/" followed by any non-empty string (e.g., "/something")
            const pathPattern = /^\/[a-zA-Z0-9_-]+/;
            
            // Redirect if the path doesn't match the pattern
            if (!pathPattern.test(location.pathname)) {
                navigate('/dashboard', { replace: true });
            } else {
                // If we're on the dashboard route, modify the URL to hide the /dashboard part
                if (location.pathname === '/dashboard') {
                    window.history.pushState({}, '', '/'); // Remove '/dashboard' from URL
                }
            }
        } else if (hostname === 'quickinsights-ai.com') {
            // Ensure only homepage pages are accessible
            if (
                !['/', '/contact', '/terms', '/privacy'].includes(location.pathname)
            ) {
                navigate('/', { replace: true });
            }
        }
    }, [location.pathname, navigate]);
    
    
    const isHomepage = location.pathname === '/';
    const isContactUs = location.pathname === '/contact';
    const isPrivacy = location.pathname === '/privacy';
    const isTerms = location.pathname === '/terms';
    const isLogin = location.pathname === '/login';


    const getPageTitle = (pathname) => {
        switch (pathname) {
            case '/account':
                return 'Account';
            case '/requests':
                return 'Requests';
            case '/uploads':
                return 'Uploads';
            case '/settings':
                return 'Settings';
            case '/feedback':
                return 'Feedback';
            case '/referral':
                return 'Referral';
            case '/billing':
                return 'Billing';
            case '/success':
                return 'Success';
            case '/cancel':
                return 'Cancel';
            case '/contact':
                return 'Contact Us';
            case '/privacy':
                return 'Privacy Policy';
            case '/terms':
                return 'Terms of Use';
            case '/login':
                return 'Login';
            case '/dashboard':
                return 'Dashboard';
            default:
                return 'Homepage';
        }
    };

    const pageTitle = getPageTitle(location.pathname); // Get the current page title
    const NotFound = () => <h1>404 - Page Not Found</h1>;

    // Determine if the sidebar and header should be displayed
    const showSidebar = !(isHomepage || isContactUs|| isPrivacy || isTerms || isLogin);
    const showHeader = !(isHomepage || isContactUs || isPrivacy || isTerms  || isLogin); // Add header logic

    return (
        <div className="app">
            {/* Sidebar */}
            {showSidebar && <Sidebar />}

            <div className={`content-wrapper ${showSidebar ? 'with-sidebar' : 'full-width'}`}>
                {/* Header */}
                {showHeader && <Header pageTitle={pageTitle} />} {/* Add Header */}

                {/* Main Content */}
                <div className="main-content">
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/account" element={<Account />} />
                        <Route path="/requests" element={<Requests />} />
                        <Route path="/uploads" element={<Uploads />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/feedback" element={<Feedback />} />
                        <Route path="/success" element={<Success/>}/>
                        <Route path="/cancel" element={<Cancel/>}/>
                        <Route path="/referral" element={<Referral/>}/>
                        <Route path="/billing" element={<Billing/>}/>
                        
                        <Route path="/" element={<Homepage />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="/terms" element={<TermsOfUse />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

// Wrap App component with Router and UserProvider
const AppWrapper = () => (
    <UserProvider>
        <Router>
            <App />
        </Router>
    </UserProvider>
);

export default AppWrapper;