import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "../elements/Layouts.js";
import logo from "../images/quickinsights-logo-home.png";
import { Link, useLocation } from "react-router-dom";


// Main container with background color
const Container = styled(ContainerBase)`

  position: relative;
  background-color: #f1f1f1; 
  color: #4a4a4a; 
  margin-bottom: -2rem;
`;
const Divider = styled.div`
  margin-top: 1rem; /* my-16 */
  margin-bottom: 1rem; /* my-16 */
  border-bottom: 2px solid #07c692; /* border-b-2 border-[#07c692] */
  width: 100%;
  transition: border-color 0.3s;
  &:hover {
    border-color: #07c692; /* hover:border-[#07c692] */
  }
`;

// Content container with responsive padding
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

// Row for layout
const Row = tw.div`flex items-center justify-between px-8 flex-wrap`;

const LogoContainer = styled.div`
  ${tw`flex items-center justify-center md:justify-start`}

  @media (max-width: 640px) {
    margin-left: 3rem; 
  }
`;

// Logo image styling
const LogoImg = styled.img`
  ${tw`w-56 py-4`}
`;

// Links container
const LinksContainer = tw.div`font-medium flex flex-col md:flex-row justify-center md:justify-center items-center md:items-center w-full md:w-auto md:space-x-6 md:space-y-0 space-y-4`;

// Individual links
const NavLink = styled(Link)`
  ${tw`text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:no-underline`}
  color: #333;
  text-decoration: none;
  &:hover {
    color: #07c692;
  }
`;

// Copyright text
const CopyrightText = tw.p`text-center mt-8 w-full font-medium tracking-wide text-sm text-gray-600`;

const LoadingOverlay = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-75`}
  z-index: 1000;
`;


const Spinner = styled.div`
  ${tw`w-12 h-12 border-4 border-t-4 border-t-[#07c692] border-gray-300 rounded-full animate-spin`}
`;

// Footer navigation links component
const NavFooterLinksComponent = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Show loading effect on route change
    setLoading(true);

    const timer = setTimeout(() => {
      setLoading(false);
      // Ensure the page scrolls to the top after navigation
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500); // Adjust the timeout for the desired loading effect duration

    return () => clearTimeout(timer);
  }, [location.pathname]);

  const handleReload = (e, path) => {
    e.preventDefault(); // Prevent default navigation
    window.location.href = path; // Trigger a full page reload
  };

  return (
    <>
      {loading && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
      <NavLink to="/" onClick={(e) => handleReload(e, "/")}>Home</NavLink>
      <NavLink
        to={
          location.pathname === "/privacy"
            ? "/#features"
            : location.pathname === "/terms"
            ? "/#features"
            : location.pathname === "/contact"
            ? "/#features"
            : "#features"
        }
      >
        Features
      </NavLink>
      <NavLink to="/privacy" onClick={(e) => handleReload(e, "/privacy")}>
        Privacy Policy
      </NavLink>
      <NavLink to="/terms" onClick={(e) => handleReload(e, "/terms")}>
        Terms of Use
      </NavLink>
      <NavLink to="/contact"  onClick={(e) => handleReload(e, "/contact")}
      >Contact Us</NavLink>
    </>
  );
};


// Footer component
const Footer = () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} alt="QuickInsights Logo" />
          </LogoContainer>
          <LinksContainer>
            <NavFooterLinksComponent />
          </LinksContainer>
        </Row>
        <Divider />
        <CopyrightText>
          &copy; {new Date().getFullYear()}, QuickInsights. All Rights Reserved.
        </CopyrightText>
      </Content>
    </Container>
  );
};

export default Footer;
