import React, { useEffect, useState } from 'react';
import './styles/dashboard.css';
import { useUser, getUserPlan } from '../context/UserContext';
import UsageStatistics from './UsageStatistics';
import APITokenLimit from './APITokenLimit';
import RecentActivity from './RecentActivity';

const Dashboard = () => {
    const { user, isSignedIn, loading, error } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false); // State for modal visibility

    useEffect(() => {
        if (!loading) {
            if (isSignedIn && user) {
                const accessToken = localStorage.getItem('accessToken');
                const userPlan = getUserPlan(user); 
                if (accessToken) {
                    window.postMessage({ action: 'authSuccess', accessToken, userPlan }, '*');
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }
    }, [user, isSignedIn, loading]);

    const renderLoadingState = () => (
        <div className="loading-container">
            <div className="loading-spinner"></div>
        </div>
    );

    // Handle the modal close
    const closeModal = () => {
        setShowModal(false);
    };

    const openModal = (event) => {
        event.preventDefault();  // Prevent default behavior of the link
        setShowModal(true);  // Open the modal
    };

    return (
        <>
            {isLoading ? (
                renderLoadingState()
            ) : (
                <main className="dashboard">
                    <header className="dashboard-header">
                        <h2>
                            Hello, {user ? (user.given_name || user.name.split(' ')[0] || 'Guest') : 'Guest'} 
                            <span className="emoji"> 👋</span>
                            {/* Get Started link */}
                            <a href="#" className="get-started-link" onClick={openModal}> Get Started Here</a>
                        </h2>
                    </header>
                    <section className="dashboard-content">
                        <div className="dashboard-grid">
                            <UsageStatistics user={user} />
                            <APITokenLimit user={user} />
                            <RecentActivity user={user} />
                        </div>
                    </section>
                </main>
            )}

            {/* Modal for video */}
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="close-btn" onClick={closeModal}>×</button>
                        <iframe
                            width="100%"
                            height="415"
                            src="https://www.youtube.com/embed/iQZeYWwYhyA"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </>
    );
};

export default Dashboard;
