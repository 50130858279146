
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, db } from '../context/UserContext';
import './styles/header.css'; // Import the updated CSS for styling
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import { ReactComponent as SearchIcon } from '../pages/Homepage/images/search-icon.svg'; // Import SVGs
import { ReactComponent as TokensIcon } from '../pages/Homepage/images/tokens-icon.svg';
import { ReactComponent as RequestsIcon } from '../pages/Homepage/images/requests-icon.svg';
import { ReactComponent as UpgradeIcon } from '../pages/Homepage/images/upgrade-icon.svg';

import { ReactComponent as ProfileIcon } from '../pages/Homepage/images/profile-icon.svg';
import { ReactComponent as SettingsIcon } from '../pages/Homepage/images/settings-icon.svg';
import { ReactComponent as ChromeIcon } from '../pages/Homepage/images/chrome-icon.svg';
import { ReactComponent as LogoutIcon } from '../pages/Homepage/images/logout-icon.svg';
import { ReactComponent as MenuIcon } from '../pages/Homepage/images/menu-icon.svg'; // New menu icon for sidebar


const Header = ({ pageTitle }) => {
    const { user, userPlan, isSignedIn, signIn, saveUserPlanToFirestore, totalRequests, setUser, logout } = useUser();
    const [searchQuery, setSearchQuery] = useState('');
    const [aiResponse, setAiResponse] = useState(null);
    const [isAiSearchEnabled, setIsAiSearchEnabled] = useState('');
    const [qiSearchBarEnabled, setQiSearchBarEnabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const navigate = useNavigate();
    const searchDropdownRef = useRef(null);
    const profileDropdownRef = useRef(null); 
    

    const [requestsCount, setRequestsCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activityLog, setActivityLog] = useState([]);
    const [fileId, setFileId] = useState(null);
    const [logData, setLogData] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const wsRef = useRef(null);

    const plan = userPlan || 'Free';

    const tokenLimits = {
        Free: 5000,
        Basic: 10000,
        Pro: 20000, 
        Enterprise: Infinity,
    };

    const [tokensUsed, setTokensUsed] = useState(0);
    const [insightsTokensUsed, setInsightsTokensUsed] = useState(0);
    const tokenLimit = tokenLimits[plan];

    const tokensRemaining = Math.max(0, tokenLimit - tokensUsed); // Prevent negative values
    const insightsTokensRemaining = Math.max(0, tokenLimits.Pro - insightsTokensUsed);


    const [sliderValue, setSliderValue] = useState(0);
    const [wordCountEnabled, setWordCountEnabled] = useState(false);
    const [wordCount, setWordCount] = useState('');
    const [selectedTone, setSelectedTone] = useState('None'); // State for text tone
    const [textStyle, setTextStyle] = useState({
        complexity: 'None',
        voice: 'None',
    });
    const [notificationsEnabled, setNotificationsEnabled] = useState(false); // State for notifications
    const [selectedAIContext, setSelectedAIContext] = useState('None'); // State for AI context settings (Pro)
    const [advancedTextAnalysis, setAdvancedTextAnalysisToggle] = useState(false); 
    const [fullPageInsights, setFullPageInsightsToggle] = useState(false); 
    const [qiSearchBar, setQiSearchBarToggle] = useState(false); 
    const [fileAttachmentsEnabled, setFileAttachmentsEnabled] = useState(false); 
    const [multipleResponsesEnabled, setMultipleResponsesEnabled] = useState(false); 
    

      // Handle login redirection
      const handleLoginClick = () => {
        // Redirect to your Google OAuth flow here instead of '/login'
        window.location.href = '/login'; // Change this to your OAuth login URL
    };


            // Handle logout functionality
            const handleLogout = async () => {
                try {
                    const accessToken = localStorage.getItem('accessToken');
        
                    if (!accessToken) {
                        console.warn('No access token found. Skipping API logout request.');
                        performLocalLogout();
                        return;
                    }
        
                    // Make a POST request to logout the user
                    const response = await fetch('https://server.quickinsights-ai.com/api/auth/logout', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`,
                        },
                    });
        
                    if (response.ok) {
                        const data = await response.json();
                        if (data.success) {
                            console.log('Dashboard logout successful:', data.message);
                            performLocalLogout();
                        } else {
                            console.error('Logout failed:', data.error);
                        }
                    } else {
                        console.error(`HTTP error during logout! Status: ${response.status}`);
                        performLocalLogout(); // Proceed with local logout even if API fails
                    }
                } catch (error) {
                    console.error('Error during logout:', error);
                    performLocalLogout(); // Proceed with local logout even on error
                }
            };
        
            // Clear user state and redirect
            const performLocalLogout = () => {
                // Clear user state and local storage
                setUser(null);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('user');
                localStorage.removeItem('userPlan');
        
                // Notify other tabs or extensions about logout
                const logoutChannel = new BroadcastChannel('logout_channel');
                logoutChannel.postMessage({ action: 'logout', message: 'User logged out' });
                logoutChannel.close();
        
                // Redirect to login page
                window.location.href = 'https://app.quickinsights-ai.com/login';
            };
        
            useEffect(() => {
                const logoutChannel = new BroadcastChannel('logout_channel');
                let webSocketHandled = false;
                let broadcastHandled = false;
            
                // WebSocket connection management
                const setupWebSocket = () => {
                    const socket = new WebSocket('wss://server.quickinsights-ai.com');
                    wsRef.current = socket; // Save WebSocket instance to ref
            
                    // Handle WebSocket open event
                    socket.onopen = () => {
                        //console.log('WebSocket connection established.');
                    };
            
                    // Handle WebSocket message event
                    socket.onmessage = (event) => {
                        try {
                            const data = JSON.parse(event.data);
                            if (data.action === 'logout' && !webSocketHandled) {
                                webSocketHandled = true; // Mark WebSocket logout as handled
                                console.log('Logout received via WebSocket:', data.message);
                                performLocalLogout();
                            }
                        } catch (error) {
                            console.error('Error parsing WebSocket message:', error);
                        }
                    };
            
                    // Handle WebSocket error event
                    socket.onerror = (error) => {
                       // console.error('WebSocket error:', error);
                    };
            
                    // Handle WebSocket close event
                    socket.onclose = (event) => {
                        setTimeout(() => {
                            setupWebSocket(); // Attempt to reconnect
                        }, 5000); // Reconnect after 5 seconds
                    };
                };
            
                // Initialize WebSocket
                setupWebSocket();
            
                // BroadcastChannel logout handling
                logoutChannel.onmessage = (event) => {
                    if (event.data.action === 'logout' && !broadcastHandled) {
                        broadcastHandled = true; // Mark BroadcastChannel logout as handled
                        console.log('Logout received via BroadcastChannel:', event.data.message);
                        performLocalLogout();
                    }
                };
            
                // Cleanup on component unmount
                return () => {
                    if (wsRef.current) {
                        wsRef.current.close(); // Close WebSocket connection
                    }
                    logoutChannel.close();
                };
            }, []);
            
            

        // Detect window resize
        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 768);
            };
    
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);


    // Function to handle click outside dropdowns
    const handleClickOutside = (event) => {
        // Check if the search dropdown is open and the click is outside it
        if (
            searchDropdownRef.current &&
            !searchDropdownRef.current.contains(event.target)
        ) {
            setShowDropdown(false); // Hide search dropdown
        }

        // Check if the profile dropdown is open and the click is outside it
        if (
            profileDropdownRef.current &&
            !profileDropdownRef.current.contains(event.target)
        ) {
            setShowProfileMenu(false); // Hide profile menu
        }
    };

    // Add event listener for clicks outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);




    useEffect(() => {
        const fetchTokens = async () => {
            if (user && user.email) {
                try {
                    const userDocRef = doc(db, "users", user.email);
                    const userDocSnapshot = await getDoc(userDocRef);
    
                    if (userDocSnapshot.exists()) {
                        const userData = userDocSnapshot.data();
    
                        // Parse tokens as numbers to ensure correct arithmetic
                        setTokensUsed(parseInt(userData.tokensUsed, 10) || 0);
                        setInsightsTokensUsed(parseInt(userData.insightsTokensUsed, 10) || 0);
                    } else {
                       // console.log("No user data found, initializing tokens to 0.");
                    }
                } catch (error) {
                    console.error("Error fetching tokens from Firestore:", error);
                }
            }
        };
    
        fetchTokens();
    }, [user]);
    

    useEffect(() => {
        if (!user?.email) return; // Skip if user or email is not available
    
        if (!wsRef.current) {
            initializeWebSocket();
        }
    
        return () => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.close();
                wsRef.current = null;
            }
        };
    }, [user?.email]);
    
    const initializeWebSocket = () => {
        const ws = new WebSocket('wss://server.quickinsights-ai.com'); // WebSocket server URL
        wsRef.current = ws;
    
        ws.onopen = () => console.log('WebSocket connected');
        ws.onmessage = (event) => handleWebSocketMessage(event); // Handle messages
        ws.onerror = (error) => console.error('WebSocket error:', error);
        ws.onclose = () => console.log('WebSocket disconnected');
    };
    
    const handleWebSocketMessage = (event) => {
        try {
            const data = JSON.parse(event.data);
            const { action, tokensUsed = 0, insightsTokensUsed = 0 } = data;
    
            if (user && user.email) {
                if (action === "updateInsightsTokens" || action === "updateTokens") {
                    console.log(
                        `Saving tokens to Firestore: action=${action}, tokensUsed=${tokensUsed}, insightsTokensUsed=${insightsTokensUsed}`
                    );
    
                    saveUserPlanToFirestore(
                        user.email,
                        plan,
                        action === "updateTokens" ? parseInt(tokensUsed, 10) : 0,
                        action === "updateInsightsTokens" ? parseInt(insightsTokensUsed, 10) : 0,
                        db
                    );
                } else {
                    console.warn("Unhandled WebSocket action:", action);
                }
            }
        } catch (error) {
            console.error("Error parsing WebSocket message:", error);
        }
    };
    
    

    const waitForWebSocketConnection = (socket) => {
        return new Promise((resolve) => {
            if (socket.readyState === WebSocket.OPEN) {
                resolve(); // If WebSocket is already open, resolve immediately
            } else {
                socket.onopen = () => {
                    console.log('WebSocket connection is now open.');
                    resolve(); // Resolve when WebSocket is open
                };
            }
        });
    };
    
    const sendInsightsTokensToDashboard = async (insightsTokensUsed) => {
        const ws = wsRef.current; // Access WebSocket instance from ref
        if (!ws) {
            console.error('WebSocket instance is not initialized.');
            return;
        }
    
        try {
            // Wait for the WebSocket connection to open
            await waitForWebSocketConnection(ws);
    
            // Send the data after the connection is open
            ws.send(JSON.stringify({ action: 'updateInsightsTokens', insightsTokensUsed }));
            console.log('Sending tokens to dashboard via WebSocket:', insightsTokensUsed);
        } catch (error) {
            console.error('Failed to send data via WebSocket:', error);
        }
    };
    
    




     
    
    const loadSettingsFromGoogleDrive = async () => {
        const accessToken = localStorage.getItem('accessToken');
    
        if (!accessToken) {
            console.warn('No access token found. Initiating sign-in...');
            signIn(); // Sign in if token is not available
            return null; // Return null explicitly
        }
    
        setIsLoading(true); // Start loading state
    
        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="settings_${user.id}.json"&mimeType="application/json"`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            const data = await response.json();
    
            if (data.files && data.files.length > 0) {
                const fileId = data.files[0].id;
    
                const fileResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
    
                if (!fileResponse.ok) {
                    throw new Error('Failed to fetch settings data');
                }
    
                const savedSettings = await fileResponse.json();
    
                // Update local state with loaded settings
                setSliderValue(savedSettings.sliderValue || 0);
                setWordCountEnabled(savedSettings.wordCountEnabled || false);
                setWordCount(savedSettings.wordCount || '');
                setSelectedTone(savedSettings.selectedTone || 'None');
                setNotificationsEnabled(savedSettings.notificationsEnabled || false);
                setSelectedAIContext(savedSettings.selectedAIContext || 'None');
                setAdvancedTextAnalysisToggle(savedSettings.advancedTextAnalysis || false);
                setFullPageInsightsToggle(savedSettings.fullPageInsights || false);
                setQiSearchBarToggle(savedSettings.qiSearchBar || false);
                setFileAttachmentsEnabled(savedSettings.fileAttachmentsEnabled || false);
                setMultipleResponsesEnabled(savedSettings.multipleResponsesEnabled || false);
                setTextStyle(savedSettings.textStyle || '');
    
                return savedSettings; // Return the loaded settings
            } else {
                //console.log('No settings file found.');
                return null; // Return null if no file is found
            }
        } catch (error) {
            console.error('Error loading settings from Google Drive:', error);
            return null; // Return null in case of error
        } finally {
            setIsLoading(false); // Stop loading state
        }
    };

        

    
    // Typing effect function
    const typeOutResponse = (text) => {
        setAiResponse(""); // Clear the previous response
        let index = 0;

        const typingInterval = setInterval(() => {
            setAiResponse((prev) => prev + text.charAt(index)); // Add one character at a time
            index++;
            
            if (index === text.length) {
                clearInterval(typingInterval); // Stop typing when done
            }
        }, 50); // Adjust speed here (in milliseconds)
    };
    // Function to copy the AI response to clipboard
    const copyToClipboard = () => {
        if (aiResponse) {
            navigator.clipboard.writeText(aiResponse)
                .then(() => {
                    console.log("Response copied to clipboard!");
                    setCopySuccess(true); // Show "Copied" message
                    setTimeout(() => setCopySuccess(false), 2000); // Hide message after 2 seconds
                })
                .catch((error) => {
                    console.error("Failed to copy text: ", error);
                });
        }
    };

// Function to analyze query using loaded settings
const autoAnalyzeQuery = async (query) => {
    setIsLoading(true);
    setAiResponse(""); // Clear previous AI response
    setAiResponse("Generating QI response... Please wait.");

    // Fetch user settings from Google Drive
    const settings = await loadSettingsFromGoogleDrive();

    // Check if settings were retrieved successfully
    if (settings) {
        const {
            outputLanguage = 'en', 
            textStyle = {}, 
            selectedAIContext = 'None', 
            sliderValue = 0, 
            wordCountEnabled = false, 
            wordCount = 0 
        } = settings;

        const languagePrompt = `Answer in ${outputLanguage}.`;
        let prompt = `Query: ${query}\n${languagePrompt}\nApply style based on context: ${selectedAIContext}.`;

        const lengthOptions = {};
        
        // Set lengthOptions based on settings
        if (wordCountEnabled && wordCount > 0) {
            lengthOptions.wordCount = wordCount;
            lengthOptions.contentLength = null; // Ignore slider-based length
        } else {
            lengthOptions.wordCount = null; // Ensure only content length applies if word count is disabled
            lengthOptions.contentLength = sliderValue === 1 ? 'short' : sliderValue === 2 ? 'medium' : sliderValue === 3 ? 'long' : null;
        }

        // Debugging to confirm lengthOptions configuration
        console.log("Final lengthOptions:", lengthOptions);

        try {
            // Fetch AI response
            const response = await getResponseFromGPT(prompt, lengthOptions, {}, textStyle);
    
            // Log response to check its type
            console.log("AI response:", response);
    
            // Handle response if it's an array
            if (Array.isArray(response) && response.length > 0) {
                const rawResponse = response[0]; // Assuming the first element contains the text
                const cleanedResponse = rawResponse.replace(/\*\*/g, "").split("\n\n").join("");
                typeOutResponse(cleanedResponse); // Pass cleaned response for typing effect
                return [{ response: cleanedResponse }];
            } 
            // Check if response is a string
            else if (typeof response === 'string') {
                const cleanedResponse = response.replace(/\*\*/g, "").split("\n\n").join("");
                typeOutResponse(cleanedResponse); // Pass cleaned response for typing effect
                return [{ response: cleanedResponse }];
            } 
            // Check if response is an object with 'text' property
            else if (response && typeof response === 'object' && response.text) {
                const cleanedResponse = response.text.replace(/\*\*/g, "").split("\n\n").join("");
                typeOutResponse(cleanedResponse); // Pass cleaned response for typing effect
                return [{ response: cleanedResponse }];
            } else {
                setAiResponse("Error: Invalid response format.");
                console.error("Unexpected response format:", response);
                return [];
            }
        } catch (error) {
            setAiResponse("Error getting response.");
            console.error("Error:", error);
            return [];
        } finally {
            setIsLoading(false); // Stop loading state
        }
    } else {
        setAiResponse("Error loading settings.");
        setIsLoading(false);
        return [];
    }
};

    
// 2. AI search function using provided AI API
const getResponseFromGPT = async (prompt, lengthOptions = {}, textStyle = {}, retries = 3) => {
    if (!isAiSearchEnabled) {
        return " QI Search is currently disabled. Please enable it in your settings to use this feature.";
    }

    // Check if tokens are exhausted, only if AI Search is enabled
    if (isAiSearchEnabled && insightsTokensRemaining <= 0) {
        return " You have reached your QI search token limit. Please upgrade your plan for token renewal.";
    }

    const requestPayload = {
        text: prompt,
        lengthOptions,
        textStyle,
    };

    let timeoutId;

    try {
        const controller = new AbortController();
        const timeoutDuration = 20000;

        timeoutId = setTimeout(() => controller.abort(), timeoutDuration);

        const response = await fetch('https://server.quickinsights-ai.com/ai-api/generative', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestPayload),
            signal: controller.signal,
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
            const responseBody = await response.text();
            console.error('Error response body:', responseBody, 'Request payload:', requestPayload);
            throw new Error(`Error: ${response.status} - ${responseBody}`);
        }

        const data = await response.json();
        const aiResponse = data.result || "No response available";

        const insightsTokensUsed = calculateSearchInsightsUsage(
            Array.isArray(aiResponse) ? aiResponse : [aiResponse]
        );

        setInsightsTokensUsed(prev => {
            const updatedTokens = prev + insightsTokensUsed;
            return updatedTokens >= tokenLimit ? prev : updatedTokens;
        });

        sendInsightsTokensToDashboard(insightsTokensUsed);

        return { message: aiResponse }; // Always return an object

    } catch (error) {
        clearTimeout(timeoutId);

        if (error.name === 'AbortError') {
            console.error("Request timed out.");
            if (retries > 0) {
                return await getResponseFromGPT(prompt, lengthOptions, textStyle, retries - 1);
            } else {
                throw new Error("Max retries reached. Unable to fetch GPT response.");
            }
        } else {
            console.error("Error fetching GPT response:", error.message || error);
            throw error;
        }
    }
};






// Updated `calculateSearchInsightsUsage` to handle arrays of strings
const calculateSearchInsightsUsage = (searchInsights) => {
    let insightsWordCount = 0;

    searchInsights.forEach((response, index) => {
        // If response is an array, process each element
        if (Array.isArray(response)) {
            console.warn(`Response ${index + 1} is an array, flattening for processing.`);
            response.forEach((subResponse, subIndex) => {
                if (typeof subResponse === 'string' && subResponse.trim()) {
                    const wordCount = subResponse.trim().split(/\s+/).length;
                    insightsWordCount += wordCount;
                    console.log(`Sub-response ${subIndex + 1}: Word Count = ${wordCount}`);
                } else {
                    console.warn(`Invalid sub-response format for index ${subIndex + 1}:`, subResponse);
                }
            });
        } else if (typeof response === 'string' && response.trim()) {
            const wordCount = response.trim().split(/\s+/).length; // Count words
            insightsWordCount += wordCount;
            console.log(`Response ${index + 1}: Word Count = ${wordCount}`);
        } else {
            console.warn(`Invalid response format for index ${index + 1}:`, response);
        }
    });

    // Assuming 1.33 tokens per word for estimation
    const calculatedTokens = Math.ceil(insightsWordCount * 1.33);
    console.log('Total Word Count:', insightsWordCount, 'Calculated Tokens:', calculatedTokens);

    return calculatedTokens; // Return the calculated tokens used for search insights
};


useEffect(() => {
    const fetchData = async () => {
        try {
            // Load log data if the user is signed in
            if (user && user.id) {
                const logData = await loadUserRequestsFromGoogleDrive();
                if (logData && logData.requests) {
                    const allRequests = Object.values(logData.requests).flat();
                    setRequestsCount(allRequests.length);
                    setLogData(logData); // Set logData for local search
                }
            }

            // Load settings if the user is signed in
            if (isSignedIn && user) {
                loadSettingsFromGoogleDrive();
            } else {
                setLoading(false); // Stop loading if user is not signed in
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    fetchData();
}, [user, isSignedIn, signIn]);


const loadUserRequestsFromGoogleDrive = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
        console.warn('No access token found. Please sign in.');
        setLoading(false);
        return null;
    }

    try {
        const fileName = `${user.id}_requests.json`;
        const response = await fetch(
            `https://www.googleapis.com/drive/v3/files?q=name="${fileName}"&mimeType="application/json"`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );

        if (!response.ok) {
            throw new Error(`Failed to fetch file metadata. Status: ${response.status}`);
        }

        const data = await response.json();

        if (!data.files?.length) {
           // console.warn(`No file named "${fileName}" found in Google Drive.`);
            return null;
        }

        const fileId = data.files[0].id;
        const fileResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );

        if (!fileResponse.ok) {
            throw new Error(`Failed to fetch file content. Status: ${fileResponse.status}`);
        }

        const logData = await fileResponse.json();
        if (logData && logData.requests) {
            const totalRequests = Object.values(logData.requests).flat().length;
            setRequestsCount(totalRequests);
        } else {
            console.warn('No requests found in the activity log data.');
        }

        return logData;
    } catch (error) {
        console.error('Error loading activity log from Google Drive:', error.message);
        return null;
    } finally {
        setLoading(false);
    }
};


// Debounce function to delay the execution of the function
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};




const handleSearch = async (query) => {
    setShowDropdown(false);
    setIsLoading(true);
    setSearchResults([]);

    // Check if query is empty
    if (!query.trim()) {
        setSearchResults([{ message: "Type in your query above." }]);
        setIsLoading(false);
        return;
    }

    // Ensure `logData` is valid
    if (!logData || !logData.requests) {
        console.error("logData is not available or invalid:", logData);
        setSearchResults([{ message: "No log data available." }]);
        setIsLoading(false);
        return;
    }


    // Local search filter based on search query
    const localResults = logData && logData.requests
        ? Object.values(logData.requests)
        .flat()
        .filter((request) => {
            // Validate request and its properties
            if (!request || typeof request !== "object") return false;

            const selectedText = typeof request.selectedText === "string" ? request.selectedText : ""; // Ensure it's a string
            const response = typeof request.response === "string" ? request.response : ""; // Ensure it's a string

            return (
                selectedText.toLowerCase().includes(query.toLowerCase()) ||
                response.toLowerCase().includes(query.toLowerCase())
            );
        })
    : [];

    let combinedResults = [];

    // Display local search results if matches found
    if (localResults.length > 0) {
        combinedResults = localResults; // Store local results
        setSearchResults(combinedResults); // Set local results to search results
        setShowDropdown(true); // Show dropdown with local results
    }
    setShowDropdown(true);

    // AI Search (if enabled)
    if (isAiSearchEnabled) {
        try {
            const aiResults = await autoAnalyzeQuery(query);

            if (aiResults && aiResults.message) {
                // Handle token limit or other AI API messages
                combinedResults.push({
                    message: aiResults.message,
                    style: aiResults.style || {},
                });
            } else if (aiResults && aiResults.length > 0) {
                combinedResults = [...combinedResults, ...aiResults];
            } else if (localResults.length === 0) {
                combinedResults.push({ message: "No AI results found." });
            }

            setSearchResults(combinedResults);
            setShowDropdown(true);

            const logEntry = {
                category: "searchInsights",
                date: new Date().toISOString(),
                response: aiResults[0]?.response,
                selectedText: query,
                userPrompt: query,
            };
            await saveActivityLogToGoogleDrive(logEntry);
        } catch (error) {
            console.error("Error during AI search:", error);
            if (localResults.length === 0) {
                combinedResults.push({ message: "AI search failed. No results found." });
            }
        }
    }

    if (combinedResults.length === 0) {
        setSearchResults([{ message: "No results found for your query." }]);
    }

    setIsLoading(false);
};



const saveActivityLogToGoogleDrive = async (data) => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
        signIn();
        return;
    }

    if (!user || !user.id) {
        console.error('User is not signed in or user ID is not available');
        return;
    }


        // Check if the token limit has been reached
        if (plan !== 'Enterprise' && insightsTokensRemaining <= 0) {
            return;
        }

    const fileName = `${user.id}_requests.json`;
    let logData = { requests: {} }; // Initialize an object for grouped data

    try {
        const fileSearchResponse = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="${fileName}" and trashed=false`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const fileSearchData = await fileSearchResponse.json();
        let fileId;

        if (fileSearchData.files && fileSearchData.files.length > 0) {
            fileId = fileSearchData.files[0].id;

            // Get the existing log file content
            const logResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const existingLog = await logResponse.json();
            
            // Ensure logData uses the grouped format
            if (existingLog.requests && typeof existingLog.requests === 'object') {
                logData.requests = existingLog.requests;
            }
        }

        // Generate a unique ID for the entry if not already present
        if (!data.id) {
            const groupKey = new Date(data.date).toISOString().split('T')[0];
            const existingEntries = logData.requests[groupKey] || [];
            data.id = `${groupKey}-${existingEntries.length}`;
        }

        // Group data by date
        const groupKey = new Date(data.date).toISOString().split('T')[0];
        if (!logData.requests[groupKey]) {
            logData.requests[groupKey] = [];
        }

        logData.requests[groupKey].push({ ...data }); // Append new data with id to the group

        // Save the log data back to Google Drive
        if (fileId) {
            const uploadResponse = await fetch(`https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=media`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(logData),
            });

            if (!uploadResponse.ok) {
                console.error('Error saving activity log to Google Drive:', await uploadResponse.text());
            } else {
                console.log('Activity log successfully updated on Google Drive.', logData);
            }
        } else {
            // Create a new file if it doesn't exist
            const newLogData = { requests: { [groupKey]: [{ ...data }] } };

            const metadata = {
                name: fileName,
                mimeType: 'application/json',
            };
            const body = new FormData();
            body.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
            body.append('media', new Blob([JSON.stringify(newLogData)], { type: 'application/json' }));

            const createResponse = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: body,
            });

            if (!createResponse.ok) {
                console.error('Error creating activity log on Google Drive:', await createResponse.json());
            } else {
                console.log('New activity log created on Google Drive.', newLogData);
            }
        }
    } catch (error) {
        console.error('Error saving activity log to Google Drive:', error);
    }
};


// Debounce for AI search
const debouncedSearchQuery = useDebounce(searchQuery, 1000); // Adjust debounce time as needed

// useEffect to trigger search automatically based on debounced value
useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
        handleSearch(debouncedSearchQuery); // Call search whenever the debounced query changes
    }
}, [debouncedSearchQuery]); // Dependency array includes debouncedSearchQuery



// Focus event handler for search input
const handleFocus = () => {
    setShowDropdown(true); // Show dropdown when the input is focused
};

const handleDropdownMouseDown = (e) => {
    e.preventDefault(); // Prevent blur event when clicking on the dropdown
};

const handleBlur = (e) => {
    if (
        !searchDropdownRef.current ||
        !searchDropdownRef.current.contains(e.relatedTarget) &&
        !e.currentTarget.contains(e.relatedTarget)
    ) {
        setShowDropdown(false); // Hide dropdown if clicking outside both
    }
};

// Render Search Results Dropdown
const handleRequestNavigation = (request) => {
    navigate(`/requests?id=${request.id}`);
};



    const toggleSidebar = () => setShowSidebar(prev => !prev);


    // Profile menu toggle
    const toggleProfileMenu = (event) => {
        event.stopPropagation(); // Prevent the click event from propagating
        setShowProfileMenu((prev) => !prev); // Toggle menu visibility
    };


    // Navigation handlers
    const handleProfileClick = () => navigate('/account');
    const handleSettingsClick = () => navigate('/settings');

        // New functions to handle click events
        const handleUpgradeClick = () => {
            navigate('/billing');
        };
    
        const handleReferralClick = () => {
            navigate('/referral');
        };
    
        const handleExtensionClick = () => {
            window.open('https://chromewebstore.google.com/detail/quickinsights-ai-text-web/njikjkooeoakadfhdihfapoepnnaalgm', '_blank');
        };

        const planHierarchy = ['Free', 'Basic', 'Pro', 'Enterprise'];
        const upgradeRequired = planHierarchy.indexOf(userPlan) < planHierarchy.length - 1;



    return (
        <header className="header-main">
            <div className="header-left">
                <h1 className="header-title">{pageTitle}</h1>
            </div>

            {/* Conditionally render based on isMobile */}
            {!isMobile && (
                <>
                    <div className="header-center">
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search with QI..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-input"
                            onFocus={handleFocus} // Show dropdown on focus
                            onBlur={handleBlur} 
                        />

                        <label className="ai-checkbox">
                            {userPlan === "Pro" || userPlan === "Enterprise" ? (
                                qiSearchBar ? (
                                    <input
                                        type="checkbox"
                                        checked={isAiSearchEnabled} // Checkbox checked when isAiSearchEnabled is true
                                        onChange={() => setIsAiSearchEnabled(!isAiSearchEnabled)}
                                    />
                                ) : (
                                    <div className="icon-overlay">
                                        <input type="checkbox" disabled />
                                        <span className="unlock-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-unlock-fill" viewBox="0 0 16 16">
                                                <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2"/>
                                            </svg>
                                            <span className="tooltip-text">Enable QI Search in settings</span>
                                        </span>
                                    </div>
                                )
                            ) : (
                                <div className="icon-overlay">
                                    <input type="checkbox" disabled />
                                    <span className="upgrade-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFD700" className="bi bi-lock-fill" viewBox="0 0 16 16">
                                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2"/>
                                        </svg>
                                        <span className="tooltip-text">Upgrade to Pro or Enterprise to use this feature</span>
                                    </span>
                                </div>
                            )}
                            <span>ASK QI</span>
                        </label>



                

                            {showDropdown && (
                                <div className="search-results-dropdown"                    
                                 ref={searchDropdownRef}
                                onMouseDown={handleDropdownMouseDown} >
                                    {isLoading ? (
                                        <div className="loading-animation">
                                            <div className="circle"></div>
                                            <div className="circle"></div>
                                            <div className="circle"></div>
                                        </div>
                                    ) : searchResults.length > 0 ? (
                                        searchResults.map((result, index) => (
                                            <div

                                                key={result.id || index} 
                                                className="search-result-item"
                                                onClick={() => handleRequestNavigation(result)}
                                              
                                           
                                            >
                                            

                                                <div className="result-card">
                                                    {result.date && <p className="result-date">{result.date}</p>}
                                                    {result.message ? (
                                                        <p className="result-text" style={result.style || {}}>
                                                            {result.message}
                                                        </p>
                                                    ) : (
                                                        <p className="result-text">
                                                            {result.selectedText?.split(" ").slice(0, 4).join(" ")}...
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="no-results">No results found</div>
                                    )}
                                    {/* AI response section */}
                                    {isAiSearchEnabled && aiResponse && (
                                        <div className="ai-response">
                                            <div className="copy-icon" onClick={copyToClipboard} title="Copy to clipboard">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                                                </svg>
                                            </div>
                                            {copySuccess && (
                                                <div className="copy-success">
                                                    <span>Copied</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-check" viewBox="0 0 16 16">
                                                        <path d="M13.854 3.146a.5.5 0 0 1 0 .708l-7.5 7.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6 10.293l7.146-7.147a.5.5 0 0 1 .708 0z"/>
                                                    </svg>
                                                </div>
                                            )}

                                            <h4>QI Response:</h4>
                                    
                                            {typeof aiResponse === 'object' && aiResponse.message ? (
                                                // Render the AI response message with the given style
                                                <p style={aiResponse.style}>{aiResponse.message}</p>
                                            ) : isLoading ? (
                                                <div className="loading-animation">
                                                    <div className="circle"></div>
                                                    <div className="circle"></div>
                                                    <div className="circle"></div>
                                                </div>
                                                ) : (
                                                    <p> {aiResponse}<span className=" typing-cursor" /></p>
                                                    
                                                )}

                                        </div>
                                    )}
                                                </div>
                                            )}
                                    </div>

                                </div>

                    <div className="header-right">
                    <div className="user-info">
                    <div className="plan-label">{userPlan} Plan</div>

                    <div className="token-info">
                        {userPlan === 'Free' || userPlan === 'Basic' ? (
                            <>
                                <div className="token-title">
                                    <TokensIcon className="icon" />
                                    <span>Tokens Remaining</span>
                                </div>
                                <div className="token-remaining">
                                    <span>{tokensRemaining}</span>
                                </div>
                            </>
                        ) : userPlan === 'Pro' ? (
                            <>
                                <div className="token-title">
                                    <SearchIcon className="icon" />
                                    <span>Search Tokens Remaining</span>
                                </div>
                                <div className="token-remaining">
                                    <span>{insightsTokensRemaining}</span>
                                </div>
                            </>
                        ) : userPlan === 'Enterprise' ? (
                            <>
                                <div className="request-title">
                                    <span>Requests Made</span>
                                    <RequestsIcon className="icon" />
                                </div>
                                <div className="request-count">
                                    <span>{requestsCount}</span>
                                </div>
                            </>
                        ) : null}
                    </div>

                </div>






                        {user ? (
                            <div className="profile-section">
                                <img
                                    src={user.profilePicture}
                                    alt={user.name}
                                    className="profile-pic"
                                    onClick={toggleProfileMenu}
                                />
                                {showProfileMenu && (
                                    <div className="profileheader-dropdown" ref={profileDropdownRef}>
                                        {/* Section: Greeting */}
                                        <div className="section">
                                            <div className="section-title">Hi, {user.name}</div>
                                            <div className="menu-item" onClick={handleProfileClick}>
                                                <ProfileIcon /> View Account Settings
                                            </div>
                                        </div>

                                        {/* Section: Plan and Tokens */}
                                        <div className="section">
                                            <div className="section-title">Plan: {userPlan}</div>
                                            {userPlan === 'Enterprise' ? (
                                                <div className="menu-item upgrade-item">Unlimited Tokens</div>
                                            ) : userPlan === 'Pro' ? (
                                                <div className="menu-item">{insightsTokensUsed} Tokens Used for Search Insights</div>
                                            ) : (
                                                <div className="menu-item">{tokensUsed} Tokens Used</div>
                                            )}
                                            {upgradeRequired && (
                                                <div className="menu-item upgrade-item" onClick={handleUpgradeClick}>
                                                    <UpgradeIcon /> Upgrade to {planHierarchy[planHierarchy.indexOf(userPlan) + 1]}
                                                </div>
                                            )}
                                        </div>



                                        {/* Section: Referral */}
                                        <div className="section">
                                            <div className="menu-item" onClick={handleReferralClick}>
                                                <span>Refer Friends</span>
                                                <span className="new-tag">NEW</span>
                                            </div>
                                        </div>

                                        {/* Section: Get Extension */}
                                        <div className="section">
                                            <div className="menu-item" onClick={handleExtensionClick}>
                                                <ChromeIcon /> Get Extension
                                            </div>
                                        </div>

                                        {/* Section: Settings */}
                                        <div className="section">
                                            <div className="menu-item" onClick={handleSettingsClick}>
                                                <SettingsIcon /> Settings
                                            </div>
                                        </div>

                                        {/* Section: Logout */}
                                        <div className="section">
                                            <div className="menu-item"  onClick={handleLogout}>
                                                <LogoutIcon /> Logout
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="profile-placeholder">Loading user...</div>
                        )}
                    </div>
                </>
            )}

                {/* Mobile View: Show Menu Button */}
                {isMobile && (
                    <div className="mobile-header"> {/* New container for flex layout */}
                        <>
                            {user ? (
                                <div className="profile-section">
                                    <img
                                        src={user.profilePicture}
                                        alt={user.name}
                                        className="profile-pic"
                                        onClick={toggleProfileMenu}
                                    />
                                    {showProfileMenu && (
                                        <div className="mobile-profileheader-dropdown" ref={profileDropdownRef}>
                                            {/* Section: Greeting */}
                                            <div className="section">
                                                <div className="section-title">Hi, {user.name}</div>
                                                <div className="menu-item" onClick={handleProfileClick}>
                                                    <ProfileIcon /> View Profile
                                                </div>
                                            </div>

                                            {/* Section: Plan and Tokens */}
                                            <div className="section">
                                                <div className="section-title">Plan: {userPlan}</div>
                                                {userPlan === 'Pro' || userPlan === 'Enterprise' ? (
                                                    <div className="menu-item upgrade-item">Unlimited Tokens</div>
                                                ) : (
                                                    <div className="menu-item">{tokensUsed} Tokens Used</div>
                                                )}
                                                {upgradeRequired && (
                                                    <div className="menu-item upgrade-item" onClick={handleUpgradeClick}>
                                                        <UpgradeIcon /> Upgrade to {planHierarchy[planHierarchy.indexOf(userPlan) + 1]}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Section: Referral */}
                                            <div className="section">
                                                <div className="menu-item" onClick={handleReferralClick}>
                                                    <span>Refer Friends</span>
                                                    <span className="new-tag">NEW</span>
                                                </div>
                                            </div>

                                            {/* Section: Get Extension */}
                                            <div className="section">
                                                <div className="menu-item" onClick={handleExtensionClick}>
                                                    <ChromeIcon /> Get Extension
                                                </div>
                                            </div>

                                            {/* Section: Settings */}
                                            <div className="section">
                                                <div className="menu-item" onClick={handleSettingsClick}>
                                                    <SettingsIcon /> Settings
                                                </div>
                                            </div>

                                            {/* Section: Logout */}
                                            <div className="section">
                                                <div className="menu-item" onClick={handleLogout}>
                                                    <LogoutIcon /> Logout
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="profile-placeholder">Loading user...</div>
                            )}

                            <button className="menu-btn" onClick={toggleSidebar}>
                                <MenuIcon className="icon" />
                            </button>
                        </>
                    </div>
                )}

                {/* Sidebar for mobile */}
                {isMobile && showSidebar && (
                    <div className={`mobile-sidebar ${showSidebar ? 'show' : ''}`}>
                        <div className="mobile-header-center">
                            <div className="mobile-search-container">
                                <input
                                    type="text"
                                    placeholder="Search with AI..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="mobile-search-input"
                                />
                                <button onClick={handleSearch} className="mobile-search-btn">
                                    <SearchIcon className="icon" />
                                </button>
                                {showDropdown && (
                                    <div className="mobile-search-results-dropdown">
                                        {searchResults.length > 0 ? (
                                            searchResults.map((result) => (
                                                <div key={result.id} className="mobile-search-result-item">
                                                    {result.text}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="no-results">No results found</div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mobile-user-info">
                            <div className="mobile-plan-label">{userPlan} Plan</div>
                            <div className="mobile-token-info">
                            {userPlan === 'Free' || userPlan === 'Basic' ? (
                                <>
                                    <div className="token-title">
                                        <TokensIcon className="icon" />
                                        <span>Tokens Remaining</span>
                                    </div>
                                    <div className="token-remaining">
                                        <span>{tokensRemaining}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="request-title">
                                        <span>Requests Made</span>
                                        <RequestsIcon className="icon" />
                                    </div>
                                    <div className="request-count">
                                        <span>{requestsCount}</span>
                                    </div>
                                </>
                            )}
                            </div>
                        </div>

                    </div>
                )}





        </header>
    );

};


export default Header;


    

    /*

    const calculateTokenUsage = (responses) => {
        const totalWordCount = responses.reduce((total, response) => total + response.split(' ').length, 0);
        const calculatedTokens = Math.ceil(totalWordCount * 1.33);
        setTokensUsed(calculatedTokens);
    };
*/





/*
    const loadActivityLogFromGoogleDrive = async () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.warn('No access token found. Please sign in.');
            signIn();
            return;
        }
    
        setLoading(true);
        try {
            const fileName = `${user.id}_requests.json`;
            const response = await fetch(
                `https://www.googleapis.com/drive/v3/files?q=name="${fileName}"&mimeType="application/json"`, 
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }
            );
    
            const data = await response.json();
            if (data.files && Array.isArray(data.files) && data.files.length > 0) {
                const fileId = data.files[0]?.id;
    
                const fileResponse = await fetch(
                    `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, 
                    {
                        headers: { Authorization: `Bearer ${accessToken}` }
                    }
                );
    
                if (!fileResponse.ok) throw new Error('Failed to fetch activity log data');
                
                const logData = await fileResponse.json();
                setLogData(logData);
    
                // Check for requests and calculate responses
                if (logData.requests) {
                    const allRequests = Object.values(logData.requests).flat();
                    const responses = allRequests.map(request => 
                        typeof request.response === 'string' ? request.response : '' // Ensure response is a string
                    );
    
                    // Calculate total responses
                    const totalResponses = responses.filter(response => response.trim() !== '').length;
    
                    // Call your existing function to calculate token usage
                    calculateTokenUsage(responses);
    
                    // Return logData with totalResponses
                    return { ...logData, totalResponses };
                } else {
                    console.warn('No requests found in the activity log data. Expected an object.');
                    return { requests: {}, totalResponses: 0 };
                }
            } else {
                console.warn('No activity log found for this user, creating a new one');
                return { requests: {}, totalResponses: 0 };
            }
        } catch (error) {
            console.error('Error loading activity log from Google Drive:', error);
        } finally {
            setLoading(false);
        }
    };
    
    */
    
/*


    const loadUserRequestsFromGoogleDrive = async () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.warn('No access token found. Please sign in.');
            setLoading(false);
            return null;
        }
    

    
        try {
            const fileName = `${user.id}_requests.json`;
            const response = await fetch(
                `https://www.googleapis.com/drive/v3/files?q=name="${fileName}"&mimeType="application/json"`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );
    
            if (!response.ok) {
                throw new Error(`Failed to fetch file metadata. Status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if (!data.files?.length) {
                console.warn(`No file named "${fileName}" found in Google Drive.`);
                return null;
            }
    
            const fileId = data.files[0].id;
            const fileResponse = await fetch(
                `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );
    
            if (!fileResponse.ok) {
                throw new Error(`Failed to fetch file content. Status: ${fileResponse.status}`);
            }
    
            const logData = await fileResponse.json();
            if (logData && logData.requests) {
                const allResponses = Object.values(logData.requests).flatMap((requestArray) =>
                    requestArray.flatMap((request) => {
                        const responses = Array.isArray(request.response) ? request.response : [request.response];
                        return responses.filter((res) => typeof res === 'string' && res.trim() !== '');
                    })
                );
    
                calculateTokenUsage(allResponses);
    
                if (userPlan === 'Pro') {
                    const searchInsights = Object.values(logData.requests).flatMap((requestArray) =>
                        requestArray.filter((request) => request.category === 'searchInsights')
                    );
    
                    const searchResponses = searchInsights.flatMap((insight) => {
                        if (insight.response) {
                            if (Array.isArray(insight.response)) {
                                return [insight.response.join('\n')];
                            } else if (typeof insight.response === 'string') {
                                return [insight.response];
                            }
                        }
                        console.warn('Invalid response format in search insights:', insight.response);
                        return [];
                    });
    
                    calculateSearchInsightsUsage(searchResponses);
                } else if (userPlan === 'Enterprise') {
                    const totalRequests = Object.values(logData.requests).flat().length;
                    setRequestsCount(totalRequests);
                }
            } else {
                console.warn('No requests found in the activity log data.');
            }
    
            return logData;
        } catch (error) {
            console.error('Error loading activity log from Google Drive:', error.message);
            return null;
        } finally {
            setLoading(false);
        }
    };
    



/*    
    const calculateTokenUsage = (responses) => {
        let totalWordCount = 0;
    
        responses.forEach((response) => {
            const wordCount = response.split(/\s+/).length; // Count words
            totalWordCount += wordCount;
        });
    
        const totalTokensUsed = Math.ceil(totalWordCount * 1.33);
        setTokensUsed(totalTokensUsed); // Set the total tokens used
    
        const remainingTokens = Math.max(0, 
            userPlan === 'Free' 
                ? freePlanLimit - totalTokensUsed 
                : basicPlanLimit - totalTokensUsed
        );
    
        setTokensRemaining(remainingTokens); // Set the remaining tokens
    };
    
  
    const calculateSearchInsightsUsage = (searchInsights) => {
        let insightsWordCount = 0;
    
        searchInsights.forEach((response) => {
            if (typeof response === 'string') {
                const wordCount = response.split(/\s+/).length; // Count words
                insightsWordCount += wordCount;
            } else {
                console.warn('Invalid response format in search insights:', response);
            }
        });
    
        const insightsTokensUsed = Math.ceil(insightsWordCount * 1.33);
        setInsightsTokensUsed(insightsTokensUsed); // Set the total insights tokens used
    
        const searchTokensRemaining = Math.max(0, proPlanSearchLimit - insightsTokensUsed);
        setSearchTokensRemaining(searchTokensRemaining); // Set the remaining search tokens
    };
*/