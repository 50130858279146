import React, { useEffect } from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import { Container, ContentWithPaddingXl } from "../elements/Layouts.js";
import tw from "twin.macro";
import Footer from "../components/MiniCenteredFooter.js";
import ContactUsForm from "../components/TwoColContactUsWithIllustrationFullForm.js";
import { useUser } from '../../../context/UserContext.js';
import { motion } from "framer-motion";
import styled from "styled-components";
import { Link, useLocation } from 'react-router-dom';

import logo from "../images/quickinsights-logo-home.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { SectionHeading } from "../elements/Headings.js";
import { ReactComponent as ChromeWhiteIcon } from "../images/chrome-white-icon.svg"; 

const HeaderWrapper = styled.div`
  width: 100%; 
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
`;

const Header = styled.header`
  ${tw`flex justify-between items-center max-w-screen-xl mx-auto pt-0 mt-0 px-4`} 
  padding-bottom: 20px;
`;



export const NavLinks = tw.div`flex flex-col  lg:flex-row lg:justify-center`;

const NavLink = styled.a`
  ${tw`text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:no-underline`}
  color: #333; 
  text-decoration: none;
  &:hover {
    color: #07c692; 
  }
`;

const PrimaryLink = tw(NavLink)`
  lg:mx-0 px-8 py-3 rounded bg-[#07c692] text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline no-underline
`;



const LogInButton = styled.a`
  border: 1px solid #999;
  color: #333;
  background-color: #f4f4f4;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 1rem;  
  text-wrap-mode: nowrap;


  &:hover {
    border-color: #000;
    color: #07c692;
  }
`;

// Define GetStartedButton with specific styles using styled-components
const GetStartedButton = styled.a`
  background-color: #07c692;
  color: white;
  padding: 0.35rem 0.8rem;
  border-radius: 1.975rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  gap: 10px; 
  display: flex;
  align-items: center;  
  justify-content: center;
  text-wrap-mode: nowrap;

  &:hover {
    background-color: #333;
    color: #white;
  }
`;




const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-64 mr-3`}
    margin-left: -40px; 
  }
`;

const MobileNavLinksContainer = tw.nav`
  flex flex-1 items-center justify-between lg:hidden  
`;

const NavToggle = tw.button`
  lg:hidden z-50 focus:outline-none text-white border-white transition duration-300
`;
const MenuIconStyled = tw(MenuIcon)`
  w-6 h-6 fill-current text-white
`;

const CloseIconStyled = tw(CloseIcon)`
  w-6 h-6 fill-current text-white
`;

const MobileNavLinks = motion.create(styled.div`
  ${tw`lg:hidden fixed top-0 left-0 w-full max-w-screen-xl bg-white z-50 p-8`}
  border: 1px solid rgba(0, 0, 0, 0.1); /* Light border */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 50vh; /* Ensure at least 50% of the screen height */
  max-height: calc(100vh - 16px); /* Prevent it from taking full height */
  overflow-y: auto; /* Enable scrolling if content overflows */
  margin-bottom: 1rem; /* Add margin after the menu */
`);


const MobileNavHeader = tw.div`
  flex justify-between items-start w-full mb-8
`;

const MobileMenuLinks = tw.div`
  flex flex-col items-start space-y-6 text-lg
  pl-4  
`;

const MobileNavButtons = tw.div`
  flex flex-col mt-auto items-start space-y-4 w-full pl-4  
`;


const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 items-center justify-between
`;

const DesktopNavContainer = styled.div`
  ${tw`flex items-center justify-end space-x-6`}
  @media (max-width: 1024px) {
    ${tw`justify-start`} 
    }
`;

const MyHeader = ({ links }) => {
  const [showNavLinks, setShowNavLinks] = React.useState(false);

  const toggleNavbar = () => setShowNavLinks((prev) => !prev);
  const { user } = useUser(); 

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="Logo" />
    </LogoLink>
  );

  return (
    <HeaderWrapper> {/* Full-width container for border */}
      <Header>
        {/* Desktop menu */}
        <DesktopNavLinks>
          {defaultLogoLink}
          <DesktopNavContainer>
            {links}
            {user ?  (
              <LogInButton as={Link} to="/dashboard">
                Access Dashboard
              </LogInButton>
            ) : (
              <LogInButton href="https://app.quickinsights-ai.com/login" target="_blank" rel="noopener noreferrer">
                Log In
              </LogInButton>
            )}
            <GetStartedButton
              id="getStartedTopButton"
              href="https://chromewebstore.google.com/detail/quickinsights-ai-text-web/njikjkooeoakadfhdihfapoepnnaalgm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ChromeWhiteIcon />
              Add To Chrome
            </GetStartedButton>
          </DesktopNavContainer>
        </DesktopNavLinks>

      {/* Mobile menu */}
      <MobileNavLinksContainer>
        {defaultLogoLink}
        <NavToggle onClick={toggleNavbar}>
          {showNavLinks ? <CloseIconStyled /> : <MenuIconStyled />}
        </NavToggle>

        <MobileNavLinks
          initial={{ y: "-100%" }}
          animate={showNavLinks ? { y: "0%" } : { y: "-100%" }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <MobileNavHeader>
            {defaultLogoLink}
            <NavToggle onClick={toggleNavbar}>
              <CloseIconStyled />
            </NavToggle>
          </MobileNavHeader>

          <MobileMenuLinks>{links}</MobileMenuLinks>

          <MobileNavButtons>
          {user ?  (
                <LogInButton as={Link} to="/dashboard">
                  Access Dashboard
                </LogInButton>
              ) : (
                <LogInButton href="https://app.quickinsights-ai.com/login" target="_blank" rel="noopener noreferrer">
                  Log In
                </LogInButton>
            )}
            
                <GetStartedButton
                  id="getStartedTopButton"
                  href="https://chromewebstore.google.com/detail/quickinsights-ai-text-web/njikjkooeoakadfhdihfapoepnnaalgm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ChromeWhiteIcon />
                  Add To Chrome
                </GetStartedButton>
          </MobileNavButtons>

        </MobileNavLinks>
      </MobileNavLinksContainer>
    </Header>
    </HeaderWrapper>
  );
};



const NavLinksComponent = () => {
  const location = useLocation();
  const isContactPage = location.pathname === '/contact';
  const isTermsPage = location.pathname === '/terms';
  const isPrivacyPage = location.pathname === '/privacy';

  // Scroll to the hash section when the location changes
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const handleReload = (e, path) => {
    e.preventDefault(); // Prevent default navigation
    window.location.href = path; // Trigger a full page reload
  };


  const getAnchorLink = (anchor) => {
    if (isContactPage) {
      return `/contact${anchor}`;
    }
    if (isTermsPage || isPrivacyPage) {
      return `/${anchor}`;
    }
    return anchor;
  };

  return (
    <NavLinks key={1}>
      <NavLink as={Link} to="/" onClick={(e) => handleReload(e, "/")}>Home</NavLink>
      <NavLink as={Link} to={getAnchorLink('#features')}>Features</NavLink>
      <NavLink as={Link} to={getAnchorLink('#testimonials')}>Testimonials</NavLink>
      <NavLink as={Link} to={getAnchorLink('#pricing')}>Pricing</NavLink>
      <NavLink as={Link} to="/contact" onClick={(e) => handleReload(e, "/contact")}>Contact Us</NavLink>
    </NavLinks>
  );
};

const HeadingRow = tw.div`flex max-w-screen-lg px-4 mx-auto`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800 max-w-screen-lg px-4 mx-auto`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <MyHeader links={<NavLinksComponent />} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p class="effective-date">Effective starting: December 20, 2024</p>

            <p>
              We (the BriGxel Solutions team) run an application called QuickInsights and would love for you to use it. Our basic service is free, and we offer paid upgrades for advanced features. Our service is designed to provide AI-driven insights, enabling users to store, manage, and analyze their data efficiently. QuickInsights uses advanced Open AI and Generative technologies to deliver actionable intelligence and personalized experiences.
            </p>

            <h2>Terms of Service</h2>
            <p>
              The following terms and conditions govern all use of the QuickInsights platform (the "Platform") and all content, services, and products available at or through it. The Platform is offered subject to your acceptance without modification of all the terms and conditions contained herein and all other operating rules, policies (including, without limitation, QuickInsights' Privacy Policy), and procedures that may be published from time to time by BriGxel Solutions (collectively, the "Agreement").
            </p>
            <p>
              Please read this Agreement carefully before accessing or using the Platform. By accessing or using any part of it, you agree to be bound by the terms and conditions of this Agreement. If you do not agree to all the terms and conditions of this Agreement, then you may not access the Platform or use any services. If these terms are considered an offer by BriGxel Solutions, acceptance is expressly limited to these terms.
            </p>
            <p>
              The terms "you," "your," and "yourself" include your employees, agents, business representatives, and any other individuals you provide access to the Services through your Account (as defined below). You are responsible for ensuring that all persons who access the Services through your account are aware of these Terms and comply with them.
            </p>

            <h2>Electronic Communications</h2>
            <p>
              When you use the Platform or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Platform. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
            </p>

            <h2>Intellectual Property</h2>
            <p>
              All content included on this Platform, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of BriGxel Solutions or its content suppliers and protected by applicable copyright laws. The compilation of all content on this Platform is the exclusive property of BriGxel Solutions. All software used on this Platform is the property of BriGxel Solutions or its software suppliers. Other trademarks, service marks, graphics, and logos used in connection with the Platform may be the trademarks of third parties. Your use of the Platform grants you no right or license to reproduce or otherwise use any BriGxel Solutions or third-party trademarks.
            </p>

            <h2>Your QuickInsights Account</h2>
            <p>
              If you use this Platform, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account or password. BriGxel Solutions reserves the right to refuse service, terminate accounts, remove or edit content, or cancel orders in its sole discretion.
            </p>

            <h2>Changes</h2>
            <p>
              BriGxel Solutions reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes. Your continued use of or access to the Platform following the posting of any changes to this Agreement constitutes acceptance of those changes. BriGxel Solutions may also offer new services and/or features through the Platform in the future. Such new features and/or services shall be subject to the terms and conditions of this Agreement.
            </p>

            <h1>Payment and Renewal</h1>

              <h3>General Terms</h3>
              <p>
                Optional paid services are available on the QuickInsights platform ("Upgrades"). By selecting an Upgrade, you agree to pay BriGxel Solutions the monthly or annual subscription fees indicated for that service. Payments are charged on a pre-pay basis on the day you subscribe to an Upgrade and will cover the use of that service for the specified subscription period. Upgrade fees are non-refundable unless otherwise required by law.
              </p>
              <p>
                All prices are displayed in [USD]. Detailed descriptions of available plans and features are provided on the platform to help you make informed decisions.
              </p>

              <h3>Automatic Renewal</h3>
              <p>
                Your subscription will automatically renew unless you notify BriGxel Solutions before the end of the current subscription period. By agreeing to automatic renewal, you authorize us to collect the applicable subscription fee for the upcoming period using any payment method on file. Renewal notifications are sent prior to processing to ensure transparency. You can manage your subscription settings, including cancellation, through your account dashboard.
              </p>

              <h3>Cancellation and Termination</h3>
              <p>
                You are responsible for canceling your subscription through the account settings section of the platform. Upon cancellation, your access to paid features will continue until the end of the current billing cycle. Afterward, all premium features and associated data will be inaccessible, except as required by law.
              </p>
              <p>
                BriGxel Solutions reserves the right to suspend or terminate your account for any reason, including but not limited to violations of these terms. Termination will result in deactivation of your account and deletion of associated content. Any outstanding charges must be settled at the time of cancellation.
              </p>

              <h3>Refund Policy</h3>
              <p>
                Refunds are not offered for subscription fees already paid unless required by law. Exceptions for refunds are made solely at the discretion of BriGxel Solutions under extraordinary circumstances.
              </p>

              <h3>Fulfillment and Security</h3>
              <p>
                QuickInsights ensures secure handling of customer payment information and complies with PCI standards. All transactions on the platform are encrypted and processed via Stripe or other trusted payment processors. Customers can view accepted payment methods and card logos at checkout for clarity.
              </p>

              <h3>Customer Support</h3>
              <p>
                For any billing or subscription-related issues, please contact us through the following:
              </p>
              <ul>
                <li>Email: <a href="mailto:contact@quickinsights-ai.com">contact@quickinsights-ai.com</a></li>
                <li>Phone: +44 7360 502634</li>
                <li>Live Chat: Available during business hours on the platform</li>
              </ul>

              <h3>Promotional Offers</h3>
              <p>
                Any promotions or discounts applied to your subscription are subject to their specific terms and conditions, as displayed at the time of offer. Transparency around promotions ensures fair use and avoids misunderstandings.
              </p>

            <h1>General Representation and Warranty</h1>
            <p>
              You represent and warrant that your use of the Platform will be in strict accordance with the QuickInsights Privacy Policy, this Agreement, and all applicable laws and regulations. Your use of the Platform must not infringe or misappropriate the intellectual property rights of any third party.
            </p>

            <h2>Indemnification</h2>
            <p>
              You agree to indemnify and hold harmless BriGxel Solutions, its contractors, and licensors, and their respective directors, officers, employees, and agents from and against any claims and expenses, including attorneys’ fees, arising out of your use of the Platform or your violation of this Agreement.
            </p>

            <h2>Rules of Conduct</h2>
            <ul>
              <li>Use the Platform in violation of any applicable laws or regulations.</li>
              <li>Infringe upon or violate BriGxel Solutions' intellectual property rights or those of others.</li>
              <li>Gain unauthorized access to the Platform or its systems.</li>
              <li>Introduce malicious software, spam, or other harmful content.</li>
              <li>Engage in conduct that interferes with others' use of the Platform or that harms BriGxel Solutions or its users.</li>
            </ul>

            <h2>Responsibility</h2>
            <p>
              QuickInsights provides features for analyzing and storing data. You are responsible for ensuring that your use of the Platform complies with all applicable laws and that any data you process does not violate the rights of third parties.
            </p>

            <p>
              By using QuickInsights, you agree to these terms of use. If you have questions or concerns, please contact us at <a href="mailto:contact@quickinsights-ai.com">contact@quickinsights-ai.com</a>.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
