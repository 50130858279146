import React, { useEffect, useState } from "react";

import { useUser} from "../../context/UserContext.js";
import { Helmet } from "react-helmet";
import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Link } from "react-router-dom"; 
import AnimationRevealPage from "./helpers/AnimationRevealPage";
import Hero from "./components/TwoColumnWithFeaturesAndTestimonial.js"; 
import Features from "./components/ThreeColWithSideImage.js"; 
import Pricing from "./components/TwoPlansWithDurationSwitcher.js"; 
import Testimonials from "./components/TwoColumnWithImageAndRating.js"; 
import FAQ from "./components/SingleCol.js";
import GetStarted from "./components/GetStartedLight.js";
import Footer from "./components/MiniCenteredFooter.js";
import logo from "./images/quickinsights-logo-home.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as ChromeWhiteIcon } from "./images/chrome-white-icon.svg"; 


const HeaderWrapper = styled.div`
  width: 100%; 
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
`;

const Header = styled.header`
  ${tw`flex justify-between items-center max-w-screen-xl mx-auto pt-0 mt-0 px-4`} 
  padding-bottom: 20px;
`;



export const NavLinks = tw.div`flex flex-col  lg:flex-row lg:justify-center`;

const NavLink = styled.a`
  ${tw`text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:no-underline`}
  color: #333; 
  text-decoration: none;
  &:hover {
    color: #07c692; 
  }
`;

const PrimaryLink = tw(NavLink)`
  lg:mx-0 px-8 py-3 rounded bg-[#07c692] text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline no-underline
`;



const LogInButton = styled.a`
  border: 1px solid #999;
  color: #333;
  background-color: #f4f4f4;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 1rem;  
  text-wrap-mode: nowrap;


  &:hover {
    border-color: #000;
    color: #07c692;
  }
`;

// Define GetStartedButton with specific styles using styled-components
const GetStartedButton = styled.a`
  background-color: #07c692;
  color: white;
  padding: 0.35rem 0.8rem;
  border-radius: 1.975rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  gap: 10px; 
  display: flex;
  align-items: center;  
  justify-content: center;
  text-wrap-mode: nowrap;

  &:hover {
    background-color: #333;
    color: #white;
  }
`;




const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-64 mr-3`}
    margin-left: -40px; 
  }
`;

const MobileNavLinksContainer = tw.nav`
  flex flex-1 items-center justify-between lg:hidden  
`;

const NavToggle = tw.button`
  lg:hidden z-50 focus:outline-none text-white border-white transition duration-300
`;
const MenuIconStyled = tw(MenuIcon)`
  w-6 h-6 fill-current text-white
`;

const CloseIconStyled = tw(CloseIcon)`
  w-6 h-6 fill-current text-white
`;

const MobileNavLinks = motion.create(styled.div`
  ${tw`lg:hidden fixed top-0 left-0 w-full max-w-screen-xl bg-white z-50 p-8`}
  border: 1px solid rgba(0, 0, 0, 0.1); /* Light border */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 50vh; /* Ensure at least 50% of the screen height */
  max-height: calc(100vh - 16px); /* Prevent it from taking full height */
  overflow-y: auto; /* Enable scrolling if content overflows */
  margin-bottom: 1rem; /* Add margin after the menu */
`);


const MobileNavHeader = tw.div`
  flex justify-between items-start w-full mb-8
`;

const MobileMenuLinks = tw.div`
  flex flex-col items-start space-y-6 text-lg
  pl-4  
`;

const MobileNavButtons = tw.div`
  flex flex-col mt-auto items-start space-y-4 w-full pl-4  
`;


const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 items-center justify-between
`;

const DesktopNavContainer = styled.div`
  ${tw`flex items-center justify-end space-x-6 whitespace-nowrap`}
  @media (max-width: 1024px) {
    ${tw`justify-start`} 
    }
`;


const MyHeader = ({ links }) => {
  const [showNavLinks, setShowNavLinks] = React.useState(false);
  const toggleNavbar = () => setShowNavLinks((prev) => !prev);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { loading } = useUser();

  // Initialize login status from localStorage
  useEffect(() => {
    const storedLoginStatus = localStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(storedLoginStatus);
  }, []);

  // WebSocket connection to update login status dynamically
  useEffect(() => {
    let ws;
    const connectWebSocket = () => {
      ws = new WebSocket("wss://server.quickinsights-ai.com");
  
      ws.onopen = () => console.log("WebSocket connected.");
      ws.onmessage = (message) => {
        try {
          const data = JSON.parse(message.data);
          if (data.action === "updateLoginStatus") {
            setIsLoggedIn(data.isLoggedIn);
            localStorage.setItem("isLoggedIn", data.isLoggedIn);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };
  
     // ws.onerror = (error) => console.error("WebSocket error:", error);
      ws.onclose = (event) => {
        console.log("WebSocket disconnected:", event);
        if (event.code !== 1000) {
          console.warn("WebSocket disconnected unexpectedly. Reconnecting in 5 seconds...");
          setTimeout(connectWebSocket, 5000);
        }
      };
    };
  
    connectWebSocket();
  
    return () => ws && ws.close();
  }, []);
  

    // BroadcastChannel listener for logout events
    useEffect(() => {
      const logoutChannel = new BroadcastChannel("logout_channel");
  
      logoutChannel.onmessage = (event) => {
        if (event.data.action === "logout") {
          console.log("Logout detected:", event.data.message);
  
          // Update login status and persist in localStorage
          setIsLoggedIn(false);
          localStorage.removeItem("isLoggedIn");
          localStorage.setItem("isLoggedIn", false);
        }
      };
      return () => {
        logoutChannel.close(); // Clean up on component unmount
      };
    }, []);

  const renderLoginButton = () => {
    if (loading) return <button disabled>Loading...</button>;
    return isLoggedIn ? (
      <LogInButton
        href="https://app.quickinsights-ai.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Access Dashboard
      </LogInButton>
    ) : (
      <LogInButton
        href="https://app.quickinsights-ai.com/login"
        target="_blank"
        rel="noopener noreferrer"
      >
        Log In
      </LogInButton>
    );
  };

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="Logo" />
    </LogoLink>
  );
  return (
    <HeaderWrapper>
      <Header>
        <DesktopNavLinks>
          {defaultLogoLink}
          <DesktopNavContainer>
            {links}
            {renderLoginButton()}
            <GetStartedButton
              id="getStartedTopButton"
              href="https://chromewebstore.google.com/detail/quickinsights-ai-text-web/njikjkooeoakadfhdihfapoepnnaalgm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ChromeWhiteIcon />
              Add To Chrome
            </GetStartedButton>
          </DesktopNavContainer>
        </DesktopNavLinks>

        <MobileNavLinksContainer>
          {defaultLogoLink}
          <NavToggle onClick={toggleNavbar}>
            {showNavLinks ? <CloseIconStyled /> : <MenuIconStyled />}
          </NavToggle>
          <MobileNavLinks
            initial={{ y: "-100%" }}
            animate={showNavLinks ? { y: "0%" } : { y: "-100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <MobileNavHeader>
              {defaultLogoLink}
              <NavToggle onClick={toggleNavbar}>
                <CloseIconStyled />
              </NavToggle>
            </MobileNavHeader>
            <MobileMenuLinks>{links}</MobileMenuLinks>
            <MobileNavButtons>
              {renderLoginButton()}
              <GetStartedButton
                id="getStartedTopButton"
                href="https://chromewebstore.google.com/detail/quickinsights-ai-text-web/njikjkooeoakadfhdihfapoepnnaalgm"
                target="_blank"
                rel="noopener noreferrer"
              >
                 <ChromeWhiteIcon />
                Add To Chrome
              </GetStartedButton>
            </MobileNavButtons>
          </MobileNavLinks>
        </MobileNavLinksContainer>
      </Header>
    </HeaderWrapper>
  );
};




const navLinks = (
  <>
    <NavLinks key={1}>
      <NavLink as={Link} to="/">Home</NavLink>
      <NavLink href="#features">Features</NavLink>
      <NavLink href="#testimonials">Testimonials</NavLink>
      <NavLink href="#pricing">Pricing</NavLink>
      <NavLink as={Link} to="/contact">Contact Us</NavLink>
    </NavLinks>



  </>
);


const HomePage = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>QuickInsights - AI Insights at Your Fingertips</title>
        <meta
          name="description"
          content="Discover amazing features of QuickInsights AI. Flexible plans, trusted by our clients. Join now!"
        />
        <meta
          property="og:title"
          content="QuickInsights - AI Insights at Your Fingertips"
        />
        <meta
          property="og:description"
          content="Discover amazing features of QuickInsights AI. Flexible plans, trusted by our clients. Join now!"
        />
        <meta property="og:image" content="./images/homepage-thumb.png" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://quickinsights-ai.com" />
      </Helmet>
      <AnimationRevealPage>
        <MyHeader links={navLinks} />
        <Hero />
        
        <Features 
          id="features" // Ensure IDs are correct for anchor links
          heading={<>Amazing <span tw="text-gray-500">Features</span></>} 
        />
        <Testimonials 
          id="testimonials" // Ensure IDs are correct for anchor links
          heading={<>Our Clients <span tw="text-gray-500">Love Us</span></>} 
        />
        <Pricing  
          id="pricing" // Ensure IDs are correct for anchor links
          heading={<>Flexible <span tw="text-gray-500">Plans</span></>} 
        />
        <FAQ
          heading={<>Any <span tw="text-gray-500">Questions?</span></>} 
        />
        <GetStarted />
        <Footer />
      </AnimationRevealPage>
     </>
  );
};

export default HomePage;
