import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../elements/Headings";
import { PrimaryButton as PrimaryButtonBase } from "../elements/Buttons.js";
import EmailIllustrationSrc from "../images/email.svg";

// Container for the entire section
const Container = styled.div`
  position: relative;
`;

// Two-column layout with responsive design
const TwoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 2rem 4rem;
  }
`;

// Column layout, adjusting widths based on screen size
const Column = styled.div`
  width: 100%;
  max-width: 28rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: none;
    margin: 0;
  }
`;

const ImageColumn = styled(Column)`
  @media (min-width: 768px) {
    width: 41.666667%;
    flex-shrink: 0;
    height: 20rem;

    @media (min-width: 1024px) {
      height: auto;
    }
  }
`;

const TextColumn = styled(Column)`
  @media (min-width: 768px) {
    width: 58.333333%;
    margin-top: 4rem;

    @media (min-width: 1024px) {
      margin-top: 0;
    }

    ${({ textOnLeft }) =>
      textOnLeft
        ? `
        margin-right: 3rem;
        order: -1;
      `
        : `
        margin-left: 3rem;
        order: 1;
      `}
`;

// Image background with dynamic URL
const Image = styled.div`
  background-image: url("${(props) => props.imagesrc}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  border-radius: 0.375rem;
`;

// Text content container
const TextContent = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

// Subheading styled component
const Subheading = styled.h4`
  text-align: center;
  color: #07c692;
  font-size: 1.125rem;
  font-weight: 700;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

// Heading styled component
const Heading = styled.h2`
  margin-top: 1rem;
  font-weight: 900;
  font-size: 2rem;
  text-align: center;
  color: #333;

  @media (min-width: 768px) {
    text-align: left;
    font-size: 3rem;
  }

  @media (min-width: 1024px) {
    font-size: 4rem;
  }
`;

// Description styled component
const Description = styled.p`
  margin-top: 1rem;
  text-align: center;
  font-size: 0.875rem;
  color: #777;

  @media (min-width: 768px) {
    text-align: left;
    font-size: 1rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.125rem;
  }
`;

// Form container
const Form = styled.form`
  margin-top: 2rem;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

// Input field styling
const Input = styled.input`
  margin-top: 1.5rem;
  padding: 0.75rem;
  border-bottom: 2px solid #333;
  font-weight: 500;
  transition: border 0.3s ease;

  &:focus {
    outline: none;
    border-color: #07c692;
  }
`;

// Textarea with custom height
const Textarea = styled(Input).attrs({ as: "textarea" })`
  height: 6rem;
`;

// Submit button styling
const SubmitButton = styled.button`
  display: inline-block;
  margin-top: 2rem;
  padding: 0.75rem 2rem;
  border: 2px solid #07c692;
  background-color: transparent;
  color: #07c692;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #07c692;
    color: #fff;
  }
`;

const handleContactFormSubmit = async (e) => {
  e.preventDefault();

  const formData = {
    email: e.target.email.value,
    name: e.target.name.value,
    subject: e.target.subject.value,
    message: e.target.message.value,
  };

  try {
    const response = await fetch("https://server.quickinsights-ai.com/api/auth/contact/send-email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (data.success) {
      alert("Your message has been sent!");
    } else {
      alert("Error: " + data.error);
    }
  } catch (error) {
    console.error("Failed to send contact form:", error);
    alert("Failed to send message. Please try again later.");
  }
};

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Have questions about Quick Insights or need advice on how to best utilize the extension? Our team is here to help! Please fill out the form below, and we’ll get back to you as soon as possible.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imagesrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn $textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod} onSubmit={handleContactFormSubmit}>
              <Input type="email" name="email" placeholder="Your Email Address" />
              <Input type="text" name="name" placeholder="Full Name" />
              <Input type="text" name="subject" placeholder="Subject" />
              <Textarea name="message" placeholder="Your Message Here" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
