import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-9.svg";
import { ReactComponent as ChromeIcon } from "../images/chrome-icon.svg"; // Import the Chrome icon
import { ContentWithPaddingXl, Container } from "../elements/Layouts";

// Main container with styling
const PrimaryBackgroundContainer = styled.div`
  padding: 4rem 1rem; 
  @media (min-width: 640px) {
    padding: 4rem 2rem; 
  }
  @media (min-width: 1024px) {
    padding: 5rem 3rem; 
  }
  background-color: #07c692;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;


const Row = styled.div`
  padding: 0 1rem;
  @media (min-width: 640px) {
    padding: 0 4rem;
  }
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
`;

const LeftColumn = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: flex-start; /* Aligns children to the start (left) */

  @media (min-width: 1024px) {
    width: 50%;
    margin-bottom: 0;
  }
`;

const Subheading = styled.h3`
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  @media (max-width: 640px) {
    align-self: center;
  }
  &:hover {
    color: white; 
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: 50%;
    align-items: flex-end;
  }
`;

const SubscribeSection = styled.div`
  width: 100%;
  max-width: 20rem;
  @media (min-width: 1024px) {
    max-width: 28rem;
  }
`;

const ColumnHeading = styled.h3`
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    text-align: left;
  }
  transition: color 0.3s ease, border-bottom 0.3s ease;
  
  &:hover {
    color: white; 
  }
`;


const SubscribeForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media (min-width: 640px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

const Input = styled.input`
  flex-grow: 1;
  width: 100%;
  background-color: white;
  color: #333;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  border: 2px solid transparent;
  &:focus {
    outline: none;
    border-color: #333;
  }
  @media (min-width: 640px) {
    border-radius: 0.5rem 0 0 0.5rem;
  }
`;

const SubscribeButton = styled.button`
  margin-top: 0.75rem;
  width: 50%;
  padding: 0.75rem 2rem;
  background-color: #333;
  color: #fff;
  font-weight: bold;
  border: 2px solid #333;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  &:hover {
    background-color: #333;
    color: white;
    transform: scale(1.05);
  }
  @media (min-width: 640px) {
    margin-top: 0;
    border-radius: 0 0.5rem 0.5rem 0;
  }
`;

const PrimaryLink = styled.a`
  display: inline-flex; /* Prevents unnecessary stretching */
  align-items: center;
  justify-content: center;
  margin-top: 1rem; /* Adds spacing below the subheading */
  font-size: 0.75rem; /* Smaller font size */
  padding: 0.4rem 1rem; /* Smaller padding */
  font-weight: bold;
  border-radius: 9999px;
  background-color: white;
  color: black;
  text-decoration: none;
  letter-spacing: 0.05em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  gap: 0.25rem; /* Reduced gap */
  max-width: 20rem; /* Limits the width */

  @media (max-width: 640px) {
    align-self: center;
  }
  @media (min-width: 640px) {
    font-size: 0.975rem; /* Adjust font size for tablets and desktops */
    padding: 0.5rem 1rem; /* Adjust padding for larger screens */
    max-width: 20rem; /* Adjust maximum width for larger screens */
  }

  &:hover {
    background-color: black;
    color: white;
    transform: scale(1.05);
  }
`;



const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 
    text-[#b9ffda] opacity-25`}
`;

const DecoratorBlob2 = styled(SvgDecoratorBlob1)`
  ${tw`absolute top-0 right-0 w-80 h-80 transform translate-x-20 -translate-y-64 
    text-[#b9ffda] opacity-25`}
`;

export default ({
  subheading = "Excited about QuickInsights?",
  primaryLinkText = "Install Extension Now",
  primaryLinkUrl = "https://chromewebstore.google.com/detail/quickinsights-ai-text-web/njikjkooeoakadfhdihfapoepnnaalgm",
  pushDownFooter = true,
}) => {

  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://server.quickinsights-ai.com/api/auth/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        alert("Subscription successful!");
      } else {
        alert("Subscription failed. Please try again.");
      }
    } catch (error) {
      alert("Error occurred while subscribing.");
    }
  };
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <LeftColumn>
              {subheading && <Subheading>{subheading}</Subheading>}
              <PrimaryLink href={primaryLinkUrl}>
                <ChromeIcon tw="w-6 h-6" />
                <span>{primaryLinkText}</span>
              </PrimaryLink>
            </LeftColumn>
            <RightColumn>
              <SubscribeSection>
                <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
                <SubscribeForm onSubmit={handleSubmit}>
                  <Input
                    type="email"
                    placeholder="Your Email Address"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                  <SubscribeButton type="submit">Subscribe</SubscribeButton>
                </SubscribeForm>
              </SubscribeSection>
            </RightColumn>
          </Row>
          <DecoratorBlobContainer>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </DecoratorBlobContainer>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
