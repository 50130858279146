import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Container, ContentWithPaddingXl } from "../Homepage/elements/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "../Homepage/elements/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../Homepage/elements/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../Homepage/images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../Homepage/images/svg-decorator-blob-7.svg";

const Subheading = tw(SubheadingBase)`mb-2 text-[#07c692] text-lg`;
const Heading = tw(SectionHeading)`text-[#07c692] text-2xl`;
const CancelContainer = tw(ContentWithPaddingXl)`flex flex-col items-center`;
const Message = tw.p`text-sm text-gray-700 mt-4`;
const Button = styled(PrimaryButtonBase)`
  ${tw`rounded-full mt-6 tracking-wider py-3 px-8 bg-[#07c692] transform hocus:translate-x-px hocus:-translate-y-px`}
  border: 2px solid #07c692;
  &:hover {
    border-color: #0a9a7c;
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-32 w-32 fill-[#07c692] opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-32 w-32 fill-[#07c692] opacity-25 transform translate-x-2/3 translate-y-1/2`}
`;

const Cancel = () => {
  const handleBillingRedirect = () => {
    window.location.href = '/billing'; // Redirect to the billing page
  };

  return (
    <Container>
      <CancelContainer>
        <Subheading>Payment Canceled</Subheading>
        <Heading>We're Sorry to See You Go</Heading>
        <Message>
          Your payment has been canceled. If this was unintentional or you'd like to try again, you can go back to the billing page to choose a plan.
        </Message>
        <Message>Thank you for considering our services!</Message>
        <Button onClick={handleBillingRedirect}>Go to Billing</Button>
      </CancelContainer>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

export default Cancel;
