// src/components/APITokenLimit.js
import React, { useEffect, useState, useRef } from 'react';
import './styles/apiTokenLimit.css';
import { useUser, db } from '../context/UserContext';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const APITokenLimit = () => {
    const { user,isSignedIn, userPlan, saveUserPlanToFirestore } = useUser();
    const plan = userPlan || 'Free';
    const [loading, setLoading] = useState(true);
    const wsRef = useRef(null); 

    const tokenLimits = {
        Free: 5000,
        Basic: 10000,
        Pro: 20000, // Defined token limit for Pro's "Search Insights"
        Enterprise: Infinity,
    };

    const [tokensUsed, setTokensUsed] = useState(0);
    const [insightsTokensUsed, setInsightsTokensUsed] = useState(0);
    const tokenLimit = tokenLimits[plan];
    
    const tokensRemaining = Math.max(0, tokenLimit - tokensUsed); // Prevent negative values
    const insightsTokensRemaining = Math.max(0, tokenLimits.Pro - insightsTokensUsed);

    const progress = tokenLimit === Infinity ? 0 : (tokensUsed / tokenLimit) * 100;
    const insightsProgress = plan === 'Pro' ? (insightsTokensUsed / tokenLimits.Pro) * 100 : 0;

    const getProgressBarColor = (progressValue) => {
        if (progressValue <= 50) return '#07c692';
        if (progressValue <= 75) return '#FFA500';
        return '#FF4500';
    };


const fetchTokens = async () => {
    if (user && user.email) {
        try {
            const userDocRef = doc(db, "users", user.email);
            const userDocSnapshot = await getDoc(userDocRef);

            if (userDocSnapshot.exists()) {
                const userData = userDocSnapshot.data();
                const tokensUsed = parseInt(userData.tokensUsed, 10) || 0;
                const insightsTokensUsed = parseInt(userData.insightsTokensUsed, 10) || 0;
                const lastResetDate = userData.lastResetDate ? new Date(userData.lastResetDate) : null;
                
                const currentDate = new Date();
               if (lastResetDate) {
                    const timeDiff = currentDate - lastResetDate;
                    const daysDiff = timeDiff / (1000 * 3600 * 24); // Difference in days

                    if (daysDiff >= 30) { // If a month has passed
                        await resetTokens(user.email); // Reset the tokens
                    }
                }

                setTokensUsed(tokensUsed);
                setInsightsTokensUsed(insightsTokensUsed);
                
                return { tokensUsed, insightsTokensUsed };
            } else {
                return { tokensUsed: 0, insightsTokensUsed: 0 };
            }
        } catch (error) {
            console.error("Error fetching tokens from Firestore:", error);
            return { tokensUsed: 0, insightsTokensUsed: 0 };
        }
    }
    return { tokensUsed: 0, insightsTokensUsed: 0 };
};

// Reset tokens function
const resetTokens = async (email) => {
    try {
        const userDocRef = doc(db, "users", email);
        await updateDoc(userDocRef, {
            tokensUsed: 0,
            insightsTokensUsed: 0,
            lastResetDate: new Date().toISOString(), // Update the last reset date
        });
        console.log('Tokens reset successfully');
    } catch (error) {
        console.error("Error resetting tokens:", error);
    }
};

// First useEffect: Fetch tokens on component mount
useEffect(() => {
    fetchTokens();
}, [user]);




const handleWebSocketMessage = (event) => {
    try {
        const data = JSON.parse(event.data);
        const { action, tokensUsed: receivedTokensUsed, insightsTokensUsed: receivedInsightsTokensUsed } = data;

        console.log("WebSocket message received:", data);

        if (user && user.email) {
            if (action === "updateTokens" || action === "updateInsightsTokens") {
                const updatedTokensUsed =
                    action === "updateTokens"
                        ? parseInt(receivedTokensUsed, 10) || 0
                        : tokensUsed;

                const updatedInsightsTokensUsed =
                    action === "updateInsightsTokens"
                        ? parseInt(receivedInsightsTokensUsed, 10) || 0
                        : insightsTokensUsed;

                setTokensUsed(updatedTokensUsed);
                setInsightsTokensUsed(updatedInsightsTokensUsed);

                console.log("Updating Firestore with new tokens:", {
                    tokensUsed: updatedTokensUsed,
                    insightsTokensUsed: updatedInsightsTokensUsed,
                });

                saveUserPlanToFirestore(
                    user.email,
                    plan,
                    null, // First name is not updated here
                    updatedTokensUsed,
                    updatedInsightsTokensUsed,
                    db
                );
            } else {
                console.warn("Unhandled WebSocket action:", action);
            }
        }
    } catch (error) {
        console.error("Error parsing WebSocket message:", error);
    }
};
    
    useEffect(() => {
        const fetchTokensAndInitializeWebSocket = async () => {
            if (!user?.email) return;
    
            // Fetch the most recent tokens before initializing WebSocket
            await fetchTokens(); 
            initializeWebSocket(); 
        };
    
        // Add a delay before running the code (e.g., 2 seconds delay)
        const delayInMs = 6000; // 6 seconds delay

        const delayedInitialization = () => {
            setTimeout(() => {
                fetchTokensAndInitializeWebSocket();
            }, delayInMs);
        };

        delayedInitialization();
    
        return () => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.close();
                wsRef.current = null;
            }
        };
    }, [user?.email]);


    const initializeWebSocket = () => {
        const ws = new WebSocket('wss://server.quickinsights-ai.com'); // WebSocket server URL
        wsRef.current = ws;
    
        ws.onopen = async () => {
            console.log('WebSocket connected');
            
            // Fetch tokens and get their values immediately
            const { tokensUsed: updatedTokensUsed } = await fetchTokens();
    
    
            if (user?.email && (plan === 'Free' || plan === 'Basic')) {
                const updatedTokensRemaining = Math.max(0, tokenLimits[plan] - updatedTokensUsed);
    
                // Send tokensRemaining only if plan is Free or Basic
                const tokensPayload = {
                    action: "updateTokensRemaining",
                    tokensRemaining: updatedTokensRemaining,
                };
                ws.send(JSON.stringify(tokensPayload));
                console.log("WebSocket tokensRemaining payload sent:", tokensPayload);
            }
        };
    
        ws.onmessage = (event) => handleWebSocketMessage(event);
        ws.onerror = (error) => console.error('WebSocket error:', error);
        ws.onclose = () => {
            console.log('WebSocket disconnected');
            wsRef.current = null;
        };
    };
    useEffect(() => {
        if (isSignedIn && user) {
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [user, isSignedIn]);

    return (
        <div className="api-token-limit">
            <h3>{plan === 'Pro' ? 'Search Insights Token Usage' : 'API Token Usage'}</h3>
            {loading ? (
                <div className="spinner"></div>
            ) : plan === 'Enterprise' ? (
                <p>Unlimited usage available</p>
            ) : (
                <div>
                    {/* Progress bar for Free and Basic plans */}
                    {(plan === 'Free' || plan === 'Basic') && (
                        <div className="progress-bar-container standard-bar">
                            <div
                                className="progress-bar standard-progress-bar"
                                style={{ width: `${progress}%`, backgroundColor: getProgressBarColor(progress) }}
                            />
                            <div className="marker orange" style={{ left: '55%' }} />
                            <div className="marker red" style={{ left: '90%' }} />
                        </div>
                    )}
    
                    {/* Progress bar for Pro plan */}
                    {plan === 'Pro' && (
                        <div className="progress-bar-container insights-bar">
                            <div
                                className="progress-bar insights-progress-bar"
                                style={{ width: `${insightsProgress}%`, backgroundColor: getProgressBarColor(insightsProgress) }}
                            />
                            <div className="marker orange" style={{ left: '55%' }} />
                            <div className="marker red" style={{ left: '90%' }} />
                        </div>
                    )}
    
                    <div className="api-token-info">
                        {/* Tokens used and remaining for Free/Basic and Pro */}
                        <p>
                            <strong>{plan === 'Pro' ? insightsTokensUsed : tokensUsed}</strong> tokens used
                            {plan === 'Pro' && ` out of ${tokenLimits.Pro}`}
                        </p>
                        <p>
                            <strong>{plan === 'Pro' ? insightsTokensRemaining : tokensRemaining}</strong> tokens remaining
                        </p>
                    </div>
    
                    {/* Token limit reached message */}
                    {(progress >= 100 || insightsProgress >= 100) && (
                        <div className="limit-reached-message" style={{ color: 'red', marginTop: '20px' }}>
                            <p><strong>You have reached your token limit!</strong></p>
                        </div>
                    )}
    
                    {/* Near limit warning */}
                    {(progress >= 85 && progress < 100) || (insightsProgress >= 85 && insightsProgress < 100) ? (
                        <p style={{ color: 'orange' }}>You're nearing your limit! Consider upgrading your plan for more tokens.</p>
                    ) : null}
    
                    {/* Upgrade prompt */}
                    {(plan === 'Free' || plan === 'Basic' || (plan === 'Pro' && insightsProgress >= 50)) && (
                        <div className="card upgrade-prompt">
                            <h4>Upgrade Your Plan</h4>
                            <p>Upgrade to our highest plan for unlimited tokens everywhere!</p>
                            <a href="/billing" className="upgrade-button" style={{ textDecoration: 'none' }}>Upgrade Now</a>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
    
};

export default APITokenLimit;
