import React, { useEffect }from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import { Container, ContentWithPaddingXl } from "../elements/Layouts.js";
import tw from "twin.macro";
import Footer from "../components/MiniCenteredFooter.js";
import { useUser } from '../../../context/UserContext.js';
import { motion } from "framer-motion";
import styled from "styled-components";
import { Link, useLocation } from 'react-router-dom';

import logo from "../images/quickinsights-logo-home.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { SectionHeading } from "../elements/Headings.js";
import { ReactComponent as ChromeWhiteIcon } from "../images/chrome-white-icon.svg"; 

const HeaderWrapper = styled.div`
  width: 100%; 
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
`;

const Header = styled.header`
  ${tw`flex justify-between items-center max-w-screen-xl mx-auto pt-0 mt-0 px-4`} 
  padding-bottom: 20px;
`;



export const NavLinks = tw.div`flex flex-col  lg:flex-row lg:justify-center`;

const NavLink = styled.a`
  ${tw`text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:no-underline`}
  color: #333; 
  text-decoration: none;
  &:hover {
    color: #07c692; 
  }
`;

const PrimaryLink = tw(NavLink)`
  lg:mx-0 px-8 py-3 rounded bg-[#07c692] text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline no-underline
`;


const LogInButton = styled.a`
  border: 1px solid #999;
  color: #333;
  background-color: #f4f4f4;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 1rem;  
  text-wrap-mode: nowrap;


  &:hover {
    border-color: #000;
    color: #07c692;
  }
`;

// Define GetStartedButton with specific styles using styled-components
const GetStartedButton = styled.a`
  background-color: #07c692;
  color: white;
  padding: 0.35rem 0.8rem;
  border-radius: 1.975rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  gap: 10px; 
  display: flex;
  align-items: center;  
  justify-content: center;
  text-wrap-mode: nowrap;

  &:hover {
    background-color: #333;
    color: #white;
  }
`;




const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-64 mr-3`}
    margin-left: -40px; 
  }
`;

const MobileNavLinksContainer = tw.nav`
  flex flex-1 items-center justify-between lg:hidden  
`;

const NavToggle = tw.button`
  lg:hidden z-50 focus:outline-none text-white border-white transition duration-300
`;
const MenuIconStyled = tw(MenuIcon)`
  w-6 h-6 fill-current text-white
`;

const CloseIconStyled = tw(CloseIcon)`
  w-6 h-6 fill-current text-white
`;

const MobileNavLinks = motion.create(styled.div`
  ${tw`lg:hidden fixed top-0 left-0 w-full max-w-screen-xl bg-white z-50 p-8`}
  border: 1px solid rgba(0, 0, 0, 0.1); /* Light border */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 50vh; /* Ensure at least 50% of the screen height */
  max-height: calc(100vh - 16px); /* Prevent it from taking full height */
  overflow-y: auto; /* Enable scrolling if content overflows */
  margin-bottom: 1rem; /* Add margin after the menu */
`);


const MobileNavHeader = tw.div`
  flex justify-between items-start w-full mb-8
`;

const MobileMenuLinks = tw.div`
  flex flex-col items-start space-y-6 text-lg
  pl-4  
`;

const MobileNavButtons = tw.div`
  flex flex-col mt-auto items-start space-y-4 w-full pl-4  
`;


const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 items-center justify-between
`;

const DesktopNavContainer = styled.div`
  ${tw`flex items-center justify-end space-x-6`}
  @media (max-width: 1024px) {
    ${tw`justify-start`} 
    }
`;


const MyHeader = ({ links }) => {
  const [showNavLinks, setShowNavLinks] = React.useState(false);

  const toggleNavbar = () => setShowNavLinks((prev) => !prev);
  const { user } = useUser(); 

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="Logo" />
    </LogoLink>
  );

  return (
    <HeaderWrapper> {/* Full-width container for border */}
      <Header>
        {/* Desktop menu */}
        <DesktopNavLinks>
          {defaultLogoLink}
          <DesktopNavContainer>
            {links}
            {user ?  (
              <LogInButton as={Link} to="/dashboard">
                Access Dashboard
              </LogInButton>
            ) : (
              <LogInButton href="https://app.quickinsights-ai.com/login" target="_blank" rel="noopener noreferrer">
                Log In
              </LogInButton>
            )}
              <GetStartedButton
                id="getStartedTopButton"
                href="https://chromewebstore.google.com/detail/quickinsights-ai-text-web/njikjkooeoakadfhdihfapoepnnaalgm"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ChromeWhiteIcon />
                Add To Chrome
              </GetStartedButton>
          </DesktopNavContainer>
        </DesktopNavLinks>

      {/* Mobile menu */}
      <MobileNavLinksContainer>
        {defaultLogoLink}
        <NavToggle onClick={toggleNavbar}>
          {showNavLinks ? <CloseIconStyled /> : <MenuIconStyled />}
        </NavToggle>

        <MobileNavLinks
          initial={{ y: "-100%" }}
          animate={showNavLinks ? { y: "0%" } : { y: "-100%" }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <MobileNavHeader>
            {defaultLogoLink}
            <NavToggle onClick={toggleNavbar}>
              <CloseIconStyled />
            </NavToggle>
          </MobileNavHeader>

          <MobileMenuLinks>{links}</MobileMenuLinks>

          <MobileNavButtons>
          {user ?  (
                <LogInButton as={Link} to="/dashboard">
                  Access Dashboard
                </LogInButton>
              ) : (
                <LogInButton href="https://app.quickinsights-ai.com/login" target="_blank" rel="noopener noreferrer">
                  Log In
                </LogInButton>
            )}
            
                <GetStartedButton
                  id="getStartedTopButton"
                  href="https://chromewebstore.google.com/detail/quickinsights-ai-text-web/njikjkooeoakadfhdihfapoepnnaalgm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ChromeWhiteIcon />
                  Add To Chrome
                </GetStartedButton>
          </MobileNavButtons>

        </MobileNavLinks>
      </MobileNavLinksContainer>
    </Header>
    </HeaderWrapper>
  );
};




const NavLinksComponent = () => {
  const location = useLocation();
  const isContactPage = location.pathname === '/contact';
  const isTermsPage = location.pathname === '/terms';
  const isPrivacyPage = location.pathname === '/privacy';

  // Scroll to the hash section when the location changes
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const handleReload = (e, path) => {
    e.preventDefault(); // Prevent default navigation
    window.location.href = path; // Trigger a full page reload
  };


  const getAnchorLink = (anchor) => {
    if (isContactPage) {
      return `/contact${anchor}`;
    }
    if (isTermsPage || isPrivacyPage) {
      return `/${anchor}`;
    }
    return anchor;
  };

  return (
    <NavLinks key={1}>
      <NavLink as={Link} to="/" onClick={(e) => handleReload(e, "/")}>Home</NavLink>
      <NavLink as={Link} to={getAnchorLink('#features')}>Features</NavLink>
      <NavLink as={Link} to={getAnchorLink('#testimonials')}>Testimonials</NavLink>
      <NavLink as={Link} to={getAnchorLink('#pricing')}>Pricing</NavLink>
      <NavLink as={Link} to="/contact" onClick={(e) => handleReload(e, "/contact")}>Contact Us</NavLink>
    </NavLinks>
  );
};




const HeadingRow = tw.div`flex max-w-screen-lg px-4 mx-auto`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800 max-w-screen-lg px-4 mx-auto`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Privacy Policy" }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []); 
  
  return (
    <AnimationRevealPage>
      <MyHeader links={<NavLinksComponent />} />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last Updated: December 20, 2024</p>

            <p>
            This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use our services. 
            It also informs you about your privacy rights and how the law protects you. 
            </p>

            <p>
              We use Your Personal data to provide and improve the Service. By using QuickInsights, You agree to the
              collection and use of information in accordance with this Privacy Policy.
            </p>

           <h1>Company Information</h1> 

            <p>QuickInsights is a brand of BriGxel Solutions LTD, a company registered in the United Kingdom. Our registered office is:</p>

            <strong>Office Address:</strong>
            <p>Office 94BG
            182-184 High Street North
            Area 1/1 East Ham
            London E6 2JA</p>

            <strong>Contact Information:</strong>
           <p> Phone: +44 7360 502634</p>
           <p> Email: contact@brigxel.com</p>

            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>
              The words of which the initial letter is capitalized have meanings defined under the following conditions.
            </p>
            <p>
              The following definitions shall have the same meaning regardless of whether they appear in singular or in
              plural.
            </p>

            <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <p>
                  <strong>You:</strong> Refers to the individual accessing or using the Service, or the company or legal entity on behalf of which such individual is accessing the Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                  refers to BriGxel Solutions LTD.
                </p>
              </li>
              <li>
                <strong>Account</strong> means a unique account created for You to access our QuickInsights or parts of our
                Service.
              </li>
              <li>
                <strong>Website</strong> refers to QuickInsights, accessible from https://quickinsights-ai.com
              </li>{" "}
              <li>
                <strong>Service</strong> refers to the QuickInsights website, application, and related tools.
              </li>
              <li>
                <strong>Country</strong> refers to: Maharashtra, India
              </li>
              <li>
                <p>
                  <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf
                  of the Company. It refers to third-party companies or individuals employed by the Company to
                  facilitate the Service, to provide the Service on behalf of the Company, to perform services related
                  to the Service or to assist the Company in analyzing how the Service is used.
                </p>
              </li>
              <li>
                <strong>Third-party Social Media Service</strong> refers to any website or any social network website
                through which a User can log in or create an account to use the Service.
              </li>
              <li>
                <p>
                  <strong>Personal Data</strong> is any information that relates to an identified or identifiable
                  individual.
                </p>
              </li>
              <li>
                <strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
                device by a website, containing the details of Your browsing history on that website among its many
                uses.
              </li>{" "}
              <li>
                <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example, the duration of a page visit).
              </li>
              <li>
              <strong>UK GDPR:</strong> Refers to the General Data Protection Regulation as retained in UK law.
              </li>
            </ul>

            <h1>Collecting and Using Your Personal Data</h1>
        
            <h3>Personal Data</h3>
            <p>
              While using Our Service, We may ask You to provide Us with certain personally identifiable information
              that can be used to contact or identify You. Personally identifiable information may include, but is not
              limited to:
            </p>
            <ul>
              <li>Email address</li> <li>First name and last name</li> {" "}
               <li>Usage Data</li>
            </ul>

            <h3>Usage Data</h3>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>
              Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
              the time spent on those pages, unique device identifiers and other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may collect certain information
              automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique
              ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet
              browser You use, unique device identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever You visit our Service or when You access
              the Service by or through a mobile device.
            </p>


            <h2>Use of Your Personal Data</h2>
              <p>Quickinsights may use your personal data for the following purposes:</p>
              <ul>
                <li>
                  <strong>To provide and maintain our Service</strong>, including monitoring the usage of our Service.
                </li>
                <li>
                  <strong>To manage your Account:</strong> to manage your registration as a user of the Service. The personal data you provide allows you access to different functionalities of the Service available to you as a registered user.
                </li>
                <li>
                  <strong>For the performance of a contract:</strong> to comply with and undertake the purchase contract for the products, items, or services you have purchased or any other contract with us through the Service.
                </li>
                <li>
                  <strong>To contact you:</strong> to contact you by email, phone, SMS, or other forms of electronic communication, such as push notifications through mobile applications, regarding updates or informative communications related to the functionalities, products, or services, including security updates, when necessary or reasonable.
                </li>
                <li>
                  <strong>To provide you</strong> with news, special offers, and general information about other goods, services, or events that we offer that are similar to those you have already purchased or shown interest in, unless you have opted out of receiving such information.
                </li>
                <li>
                  <strong>To manage your requests:</strong> to handle and manage your requests to us.
                </li>
              </ul>

              <p>We may share your personal information in the following situations:</p>

              <ul>
                <li>
                  <strong>With Service Providers:</strong> We may share your personal data with third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, web-hosting and server providers, maintenance, marketing providers, professional advisors, and payment systems operators.
                </li>
                <li>
                  <strong>To Comply with Legal Obligations:</strong> We may disclose your personal data to courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or to establish, exercise, or defend our legal rights.
                </li>
                <li>
                  <strong>For Internal Record Keeping:</strong> We may process and store your personal data for internal record-keeping and administrative purposes, in line with business operations and compliance requirements.
                </li>
                <li>
                  <strong>For Data Collection and Processing:</strong> We may share your personal data with third parties to collect and process data, such as Google Analytics or other relevant businesses. This may include parties that store data outside the UK, including in the U.S.A.
                </li>
              </ul>


              <h3>Disclosure to Third Parties</h3>
              <p>We may disclose your personal information to third-party service providers for the purpose of enabling them to provide their services, including but not limited to IT services, data storage, web hosting, server providers, maintenance, marketing services, professional advisors, and payment system operators.</p>

              <p>We may also disclose your personal information as required by law, such as in response to a court order or legal process, to comply with regulations, or to protect the legal rights of Quickinsights, its users, or others. This includes sharing information with courts, tribunals, regulatory authorities, and law enforcement officers.</p>

              <p>For internal record-keeping and administrative purposes, we may also process and store your personal information.</p>

              <p>We may share your personal information with third parties for data collection and processing, such as Google Analytics, and other relevant third-party services. This may include parties who store data outside the UK and the EU, such as in the U.S.A.</p>

              <h3>Data Processing and Transfer</h3>
              <p>Where we disclose your personal information to third parties, including data processors, we will request or ensure that the third party handles your personal information in accordance with this Privacy Policy. The third-party service providers will only process your personal data according to our instructions, and we require that they comply with applicable data protection laws, including the GDPR in the EU or equivalent laws in other regions.</p>

              <p>For users in the EU, your personal information may be transferred outside of the EU. In such cases, Quickinsights ensures that data transfers are carried out in accordance with the GDPR, including implementing appropriate safeguards for data protection, such as the EU-U.S. Privacy Shield or Standard Contractual Clauses where necessary.</p>

              <p>By providing us with personal information, you consent to the disclosure of your personal information to third parties located outside of the UK, EU, and in countries that may not offer the same level of data protection. We ensure that third parties comply with applicable data protection laws, including the UK Data Protection Act 2018 and the GDPR, where relevant.</p>

              <p>We use third-party services, including Google API Services, Google Sign-In, Google Cloud Platform, Google Firebase, Segment, Mailgun, Google Analytics, and Stripe to process your personal data in accordance with this Privacy Policy.</p>

            <h2>Retention of Your Personal Data</h2>
            <p>
              The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
              this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
              legal obligations (for example, if we are required to retain your data to comply with applicable laws),
              resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p>
              The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
              for a shorter period of time, except when this data is used to strengthen the security or to improve the
              functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            </p>

          
            <h2>Security of Your Personal Data</h2>
            <p>
              The security of Your Personal Data is important to Us, but remember that no method of transmission over
              the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
              acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
            </p>

            <h2>Your Rights</h2>

              <p>If you are a resident of the United Kingdom or the European Union, you have specific rights regarding your personal data under the General Data Protection Regulation (GDPR) (Regulation (EU) 2016/679) and the UK Data Protection Act 2018. These rights include, but are not limited to, the following:</p>

              <ul>
                <li>
                  <strong>The right of access:</strong> You have the right to request a copy of the personal data we hold about you.
                </li>
                <li>
                  <strong>The right to rectification:</strong> You can request that we correct any incorrect or incomplete personal information we hold about you.
                </li>
                <li>
                  <strong>The right to erasure (right to be forgotten):</strong> You have the right to request the deletion of your personal data, subject to certain conditions.
                </li>
                <li>
                  <strong>The right to withdraw consent:</strong> If our processing of your personal data is based on consent, you may withdraw that consent at any time.
                </li>
                <li>
                  <strong>The right to object:</strong> You have the right to object to the processing of your personal data if it is based on legitimate interests, including for direct marketing purposes.
                </li>
                <li>
                  <strong>The right to data portability:</strong> You can request that we provide your personal data in a structured, commonly used, and machine-readable format, and you have the right to transfer that data to another data controller without hindrance.
                </li>
                <li>
                  <strong>The right to restrict processing:</strong> You can request that we restrict the processing of your personal data in certain circumstances, such as when the data is inaccurate or processing is unlawful.
                </li>
              </ul>

              <p>If you are located in the European Union, the United Kingdom, or elsewhere, and you wish to exercise any of the rights outlined above, you may contact us at <a href="mailto:contact@quickinsights-ai.com">contact@quickinsights-ai.com</a>.</p>

              <p>For the purposes of the GDPR and UK Data Protection Act 2018, we are a "data controller" and you are a "data subject." We may request specific information to verify your identity when you exercise these rights, and in certain cases, we may charge a reasonable fee to cover administrative costs associated with processing your request.</p>

              <p>We may also send you marketing communications based on our legitimate interests, but you have the right to opt-out of such communications at any time. You can do this by following the unsubscribe link in any marketing email or by contacting us directly.</p>

              <h3>Privacy Shield Framework</h3>

              <p>If you are a resident of the European Union, United Kingdom, or Switzerland, you have the right to obtain confirmation of whether we maintain personal information relating to you in the United States. Upon request, we will provide you with access to your personal information, and you may request corrections, amendments, or deletions of the data we hold about you.</p>

              <p>If you need assistance with access, correction, amendment, or deletion of your personal data transferred to the United States under the Privacy Shield, please contact us at <a href="mailto:contact@quickinsights-ai.com">contact@quickinsights-ai.com</a>. We will respond to your request within a reasonable timeframe.</p>



                <h3>Tracking Technologies and Cookies</h3>
                  <p>
                    Quickinsights uses cookies and similar tracking technologies to track activity on our Service and store certain information. These tracking technologies may include beacons, tags, and scripts to collect and track information in order to improve and analyze the Service. We may use these technologies to enhance user experience, monitor website performance, and personalize content.
                  </p>
                  <p>
                    You can configure your browser to refuse all cookies or to notify you when a cookie is being sent. However, please note that if you do not accept cookies, some features of the Quickinsights Service may not be accessible or function properly.
                  </p>
                  <p>
                    Cookies can be classified as "Persistent" or "Session" cookies. Persistent cookies remain on your personal computer or mobile device even when you're offline, while session cookies are deleted once you close your web browser. For more information about cookies, please visit <a href="https://www.termsfeed.com/blog/cookies/">All About Cookies</a>.
                  </p>
                  <p>We use both session and persistent cookies for the following purposes:</p>
                  <ul>
                    <li>
                      <p>
                        <strong>Necessary / Essential Cookies</strong>
                      </p>
                      <p>Type: Session Cookies</p>
                      <p>Administered by: Quickinsights</p>
                      <p>
                        Purpose: These cookies are essential for providing the services available through the Service and enabling you to use certain features. They help authenticate users and prevent fraudulent use of accounts. Without these cookies, we cannot provide the services you've requested, and we use them solely to ensure these services are delivered.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                      </p>
                      <p>Type: Persistent Cookies</p>
                      <p>Administered by: Quickinsights</p>
                      <p>Purpose: These cookies identify whether users have accepted the use of cookies on the Service.</p>
                    </li>
                    <li>
                      <p>
                        <strong>Functionality Cookies</strong>
                      </p>
                      <p>Type: Persistent Cookies</p>
                      <p>Administered by: Quickinsights</p>
                      <p>
                        Purpose: These cookies allow us to remember choices you make when using the Service, such as remembering your login details or language preference. These cookies enhance your experience by providing a personalized service and preventing you from needing to re-enter your preferences.
                      </p>
                    </li>
                  </ul>
                  <p>
                    For more information about the cookies we use and your options regarding cookies, please visit our Cookies Policy.
                  </p>
                  <h1>Children's Privacy</h1>
                <p>
                  Quickinsights does not knowingly collect or solicit personal data from anyone under the age of 13. Our services are intended for users who are at least 13 years old. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us immediately. If we become aware that we have collected personal data from a child under the age of 13 without verification of parental consent, we will take steps to delete such information from our servers.
                </p>
                <p>
                  If we need to rely on consent as a legal basis for processing personal data and your country requires parental consent, we may request consent from your parent or guardian before collecting and processing your data.
                </p>

                <h1>Links to Other Websites</h1>
                <p>
                  Our Service may contain links to external websites or services that are not operated by Quickinsights. If you click on a third-party link, you will be directed to that third party's site. We encourage you to review the privacy policy of each website you visit.
                </p>
                <p>
                  Quickinsights has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services.
                </p>
                <h1>Changes to this Privacy Policy</h1>
                <p>
                  We may update our Privacy Policy periodically. Any changes will be communicated by posting the updated Privacy Policy on this page.
                </p>
                <p>
                  We will notify you via email and/or provide a prominent notice on our Service before the changes take effect. The "Last updated" date at the top of this Privacy Policy will also be updated accordingly.
                </p>
                <p>
                  You are encouraged to review this Privacy Policy periodically for any updates. Changes to the Privacy Policy are effective when they are posted on this page.
                </p>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
