import React, { useState } from "react";
import tw from "twin.macro";
import styled, { keyframes, css } from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../elements/Headings.js";
import { SectionDescription } from "../elements/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../elements/Buttons.js";
import { Container, ContentWithPaddingXl } from "../elements/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-7.svg";



// Define the keyframes for the border animation
const borderGlow = keyframes`
  0% {
    border-color: #07c692;
    box-shadow: 0 0 10px rgba(7, 198, 146, 0.7);
  }
  50% {
    border-color: #0a9a7c;
    box-shadow: 0 0 20px rgba(10, 154, 124, 0.9);
  }
  100% {
    border-color: #07c692;
    box-shadow: 0 0 10px rgba(7, 198, 146, 0.7);
  }
`;

// Badge for Featured Plan
const Badge = styled.span`
  ${tw`absolute top-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-br-lg`}
  transform: translate(-50%, -50%);
  
  @media (max-width: 640px) {
    left: 0px; 
    transform: translate(0%, -50%);
  }
`;



const HeaderContainer = tw.div`w-full px-4 flex flex-col items-center`;

const Subheading = tw(SubheadingBase)`mb-4 text-[#07c692]`; 

// Wrapper for heading, subheading, and description
const HeaderWrapper = styled.div`
  ${tw`w-full text-center `}
`;

const Heading = tw(SectionHeading)`text-[#333] text-xl sm:text-2xl lg:text-4xl`;
const Description = tw(SectionDescription)`w-full text-center text-gray-700 text-sm sm:text-base lg:text-lg max-w-none`;


const SectionContainer = tw(ContentWithPaddingXl)`overflow-hidden`;
const PlanDurationSwitcher = tw.div`
  flex flex-col sm:flex-row w-full max-w-xs sm:max-w-md 
  border-2 rounded-full px-4 py-1 mt-8
  justify-center sm:justify-center
  items-center sm:space-x-4
`;

const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${({ $active }) => $active && tw`bg-[#07c692] text-gray-100`}
  border: 2px solid #07c692;
  &:hover {
    background-color: #333;
    border-color: #0a9a7c;
    color: white;
  }

  /* Adjust for small screens */
  @media (max-width: 640px) {
    ${tw`w-full`} /* Make buttons take full width on small screens */
    margin-bottom: 8px; /* Add space between buttons on mobile */
  }
`;




const PlansContainer = styled.div`
  ${tw`grid 
    gap-y-8 gap-x-8 
    lg:grid-cols-4 
    md:grid-cols-2 
    sm:grid-cols-1 
    px-4 sm:px-8 
    items-start 
    relative`}
`;


const Plan = styled.div`
  ${tw`w-full lg:w-auto mt-8 text-center px-8 py-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      border: 4px solid #07c692;
      animation: ${borderGlow} 2s infinite;
      z-index: 20;
    `}

  ${({ $featured }) => $featured && `${tw`border-2 border-gray-200 shadow-none`}`}
  
  /* Ensure spacing does not conflict */
  &:not(:last-child) {
    ${tw`mb-4 lg:mb-0`}
  }
`;



const PlanHeader = styled.div`
    ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
    @media (max-width: 640px) {
      ${tw`text-center px-4`}
    }
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500 break-words`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl bg-[#07c692] transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
  border: 2px solid #07c692;
  &:hover {
    background-color:#333;
    border-color: #0a9a7c; 
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 fill-[#07c692] opacity-25 transform -translate-x-2/3 -translate-y-1/2 text-teal-300`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 fill-[#07c692] opacity-25 transform translate-x-2/3 translate-y-1/2 text-teal-300`}
`;


export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Choose the right plan for your needs. Whether you’re just starting or ready to scale, Quick AI has a solution for everyone.",
  plans = null,
  primaryButtonText = "Get Started",
  planDurations = [
    {
      text: "Month",
      switcherText: "Monthly",
    },
    {
      text: "Year",
      switcherText: "Yearly",
    }
  ]
}) => {
  const defaultPlans = [
    {
      name: "Free",
      monthlyPriceId: null, // No Price ID for Free plan
      annualPriceId: null,
      durationPrices: ["$0", "$0"],
      mainFeature: "For Personal Use",
      features: [
        "Plain Text Analysis",
        "Limited API Tokens 5000/month",
        "Content Length Limitation",
        "Mulitple Languages",
        "Basic Support"
      ],
    },
    {
      name: "Basic",
      monthlyPriceId: "price_1QX8w3FPsrUjmpfjM0KMnpmk", // Stripe Price ID for monthly billing
      annualPriceId: "price_1QX8xiFPsrUjmpfj8dCwpLKf", // Stripe Price ID for annual billing
      durationPrices: ["$9", "$99"],
      mainFeature: "Ideal for Small Teams",
      features: [
        "All Free Features",
        "Limited API Tokens 10000/month",
        "Text Styles Options",
        "Text Tone Options",
        "Priority Support"
      ],
    },
    {
      name: "Pro",
      monthlyPriceId: "price_1QX90PFPsrUjmpfj5tEqa994", // Stripe Price ID for monthly billing
      annualPriceId: "price_1QX91MFPsrUjmpfjZZew47Px", // Stripe Price ID for annual billing
      durationPrices: ["$29", "$299"],
      mainFeature: "Suited for Growing Businesses",
      features: [
        "All Basic Features",
        "Unlimited API Tokens",
        "Text Context Options",
        "Full Page Insights",
        "Priority Support"
      ],
      featured: true, // Highlight this plan in UI
    },
    {
      name: "Enterprise",
      monthlyPriceId: "price_1QX92tFPsrUjmpfjMQkm6SpX", // Stripe Price ID for monthly billing
      annualPriceId: "price_1QX93SFPsrUjmpfjs3UDlh1o", // Stripe Price ID for annual billing
      durationPrices: ["$49", "$599"],
      mainFeature: "Customized for Large Enterprises",
      features: [
        "All Pro Features",
        "Custom Solutions",
        "Multiple Response Option",
        "File Attachments",
        "Priority Support"
      ],
    },
  ];
  
  const handleGetStartedClick = () => {
    window.open('https://app.quickinsights-ai.com/login', '_blank');
  };

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <SectionContainer id="pricing">
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
        <HeaderWrapper>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          </HeaderWrapper>
          <PlanDurationSwitcher>
            {planDurations.map((planDuration, index) => (
             <SwitchButton $active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>
             {planDuration.switcherText}
           </SwitchButton>
           
            ))}
          </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
  {plans.map((plan, index) => (
    <Plan key={index} $featured={plan.featured} $isHighlighted={plan.name === "Pro"}>

      {plan.name === "Pro" && <Badge>Featured</Badge>}
  
      <PlanHeader>
        <span className="priceAndDuration">
          <span className="price">{plan.durationPrices[activeDurationIndex]}</span>
          <span className="slash"> / </span>
          <span className="duration">{planDurations[activeDurationIndex].text}</span>
        </span>
        <span className="name">{plan.name}</span>
        <span className="mainFeature">{plan.mainFeature}</span>
      </PlanHeader>
      <PlanFeatures>
        {plan.features.map((feature, index) => (
          <span key={index} className="feature">
            {feature}
          </span>
        ))}
      </PlanFeatures>
      <PlanAction>
                <BuyNowButton onClick={handleGetStartedClick}>
                  {primaryButtonText}
                </BuyNowButton>
              </PlanAction>
    </Plan>
  ))}
        </PlansContainer>

      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
    
    </SectionContainer>
  );
};
